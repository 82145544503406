.scroll-target-element {
    width: 100%;
}

.menu-scroll-link {

    color: var(--chakra-colors-secondaryGray-600);

    &.menu-scroll-link-active {
        color: var(--chakra-colors-gray-700);
        font-weight: 800;
    }
}