.apexcharts-canvas {

    .apexcharts-svg {

        .apexcharts-grid-borders>.apexcharts-gridline:nth-of-type(2) {
            stroke: var(--chakra-colors-secondaryGray-900);
        }

        .apexcharts-yaxis-label,
        .apexcharts-xaxis-label {
            fill: var(--chakra-colors-secondaryGray-900);
        }

        .apexcharts-xaxis-tick {
            stroke: var(--chakra-colors-secondaryGray-900);
        }

    }

    .apexcharts-tooltip {

        &.apexcharts-theme-light {
            background: rgba(255, 255, 255, 0.75) !important;
        }

        .apexcharts-tooltip-title {
            font-size: 14px;
            background-color: rgba(236, 239, 241, 0.75) !important;
        }

        .apexcharts-tooltip-custom {
            .apexcharts-tooltip-title {
                font-size: 12px !important;
            }

            font-size: 12px !important;

        }
    }
}