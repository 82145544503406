$calendar-header-bg: var(--chakra-colors-gray-100);
$calendar-border-color: var(--chakra-colors-gray-200);


.tw-date-picker {
    &.tw-date-picker__full-width {
        width: 100%;

        .react-datepicker-wrapper {
            width: 100%;
        }
    }
}

.tw-calendar-wrapper {
    .tw-calendar-header {
        border: 1px solid $calendar-border-color;
        background-color: #fff;
        // background-color: $calendar-header-bg;
    }

    .tw-calendar {
        border-top: none;
    }
}


.tw-calendar {

    border-radius: 0;
    border-color: $calendar-border-color;

    .react-datepicker__navigation {
        top: 6px;
    }

    .react-datepicker__header {
        background-color: $calendar-header-bg;
        border-radius: 0;
    }

}