.terms-of-use {

    ol.lst-kix_list_7-0 {
        list-style-type: none
    }

    .lst-kix_list_2-1>li {
        counter-increment: lst-ctn-kix_list_2-1
    }

    .lst-kix_list_21-8>li {
        counter-increment: lst-ctn-kix_list_21-8
    }

    ol.lst-kix_list_9-0.start {
        counter-reset: lst-ctn-kix_list_9-0 0
    }

    ol.lst-kix_list_13-4.start {
        counter-reset: lst-ctn-kix_list_13-4 0
    }

    .lst-kix_list_13-0>li {
        counter-increment: lst-ctn-kix_list_13-0
    }

    ol.lst-kix_list_20-2.start {
        counter-reset: lst-ctn-kix_list_20-2 0
    }

    .lst-kix_list_5-0>li {
        counter-increment: lst-ctn-kix_list_5-0
    }

    ol.lst-kix_list_2-3.start {
        counter-reset: lst-ctn-kix_list_2-3 0
    }

    ol.lst-kix_list_7-5 {
        list-style-type: none
    }

    ol.lst-kix_list_7-6 {
        list-style-type: none
    }

    ol.lst-kix_list_7-7 {
        list-style-type: none
    }

    ol.lst-kix_list_7-8 {
        list-style-type: none
    }

    ol.lst-kix_list_7-1 {
        list-style-type: none
    }

    ol.lst-kix_list_7-2 {
        list-style-type: none
    }

    ol.lst-kix_list_7-3 {
        list-style-type: none
    }

    ol.lst-kix_list_7-4 {
        list-style-type: none
    }

    ol.lst-kix_list_23-2.start {
        counter-reset: lst-ctn-kix_list_23-2 0
    }

    ol.lst-kix_list_5-3.start {
        counter-reset: lst-ctn-kix_list_5-3 0
    }

    .lst-kix_list_4-3>li {
        counter-increment: lst-ctn-kix_list_4-3
    }

    ol.lst-kix_list_17-1.start {
        counter-reset: lst-ctn-kix_list_17-1 0
    }

    .lst-kix_list_18-8>li {
        counter-increment: lst-ctn-kix_list_18-8
    }

    ol.lst-kix_list_8-8.start {
        counter-reset: lst-ctn-kix_list_8-8 0
    }

    ol.lst-kix_list_10-4.start {
        counter-reset: lst-ctn-kix_list_10-4 0
    }

    .lst-kix_list_7-2>li {
        counter-increment: lst-ctn-kix_list_7-2
    }

    ol.lst-kix_list_16-5 {
        list-style-type: none
    }

    .lst-kix_list_1-4>li {
        counter-increment: lst-ctn-kix_list_1-4
    }

    ol.lst-kix_list_16-6 {
        list-style-type: none
    }

    ol.lst-kix_list_16-7 {
        list-style-type: none
    }

    ol.lst-kix_list_1-6.start {
        counter-reset: lst-ctn-kix_list_1-6 0
    }

    ol.lst-kix_list_16-8 {
        list-style-type: none
    }

    ol.lst-kix_list_9-5.start {
        counter-reset: lst-ctn-kix_list_9-5 0
    }

    ol.lst-kix_list_16-1 {
        list-style-type: none
    }

    ol.lst-kix_list_16-2 {
        list-style-type: none
    }

    ol.lst-kix_list_16-3 {
        list-style-type: none
    }

    ol.lst-kix_list_16-4 {
        list-style-type: none
    }

    ol.lst-kix_list_16-0 {
        list-style-type: none
    }

    ol.lst-kix_list_20-7.start {
        counter-reset: lst-ctn-kix_list_20-7 0
    }

    .lst-kix_list_9-4>li {
        counter-increment: lst-ctn-kix_list_9-4
    }

    ol.lst-kix_list_16-4.start {
        counter-reset: lst-ctn-kix_list_16-4 0
    }

    ol.lst-kix_list_14-1.start {
        counter-reset: lst-ctn-kix_list_14-1 0
    }

    .lst-kix_list_23-6>li:before {
        content: "" counter(lst-ctn-kix_list_23-0, decimal) "." counter(lst-ctn-kix_list_23-1, decimal) "." counter(lst-ctn-kix_list_23-2, decimal) "." counter(lst-ctn-kix_list_23-3, decimal) "." counter(lst-ctn-kix_list_23-4, decimal) "." counter(lst-ctn-kix_list_23-5, decimal) "." counter(lst-ctn-kix_list_23-6, decimal) ". "
    }

    .lst-kix_list_6-5>li {
        counter-increment: lst-ctn-kix_list_6-5
    }

    .lst-kix_list_23-3>li:before {
        content: "" counter(lst-ctn-kix_list_23-0, decimal) "." counter(lst-ctn-kix_list_23-1, decimal) "." counter(lst-ctn-kix_list_23-2, decimal) "." counter(lst-ctn-kix_list_23-3, decimal) ". "
    }

    .lst-kix_list_23-7>li:before {
        content: "" counter(lst-ctn-kix_list_23-0, decimal) "." counter(lst-ctn-kix_list_23-1, decimal) "." counter(lst-ctn-kix_list_23-2, decimal) "." counter(lst-ctn-kix_list_23-3, decimal) "." counter(lst-ctn-kix_list_23-4, decimal) "." counter(lst-ctn-kix_list_23-5, decimal) "." counter(lst-ctn-kix_list_23-6, decimal) "." counter(lst-ctn-kix_list_23-7, decimal) ". "
    }

    .lst-kix_list_23-2>li:before {
        content: "" counter(lst-ctn-kix_list_23-0, decimal) "." counter(lst-ctn-kix_list_23-1, decimal) "." counter(lst-ctn-kix_list_23-2, decimal) ". "
    }

    ol.lst-kix_list_23-7.start {
        counter-reset: lst-ctn-kix_list_23-7 0
    }

    .lst-kix_list_23-0>li:before {
        content: "" counter(lst-ctn-kix_list_23-0, decimal) ". "
    }

    .lst-kix_list_23-8>li:before {
        content: "" counter(lst-ctn-kix_list_23-0, decimal) "." counter(lst-ctn-kix_list_23-1, decimal) "." counter(lst-ctn-kix_list_23-2, decimal) "." counter(lst-ctn-kix_list_23-3, decimal) "." counter(lst-ctn-kix_list_23-4, decimal) "." counter(lst-ctn-kix_list_23-5, decimal) "." counter(lst-ctn-kix_list_23-6, decimal) "." counter(lst-ctn-kix_list_23-7, decimal) "." counter(lst-ctn-kix_list_23-8, decimal) ". "
    }

    .lst-kix_list_3-6>li {
        counter-increment: lst-ctn-kix_list_3-6
    }

    .lst-kix_list_23-1>li:before {
        content: "" counter(lst-ctn-kix_list_23-0, decimal) "." counter(lst-ctn-kix_list_23-1, decimal) ". "
    }

    .lst-kix_list_2-8>li {
        counter-increment: lst-ctn-kix_list_2-8
    }

    .lst-kix_list_23-4>li:before {
        content: "" counter(lst-ctn-kix_list_23-0, decimal) "." counter(lst-ctn-kix_list_23-1, decimal) "." counter(lst-ctn-kix_list_23-2, decimal) "." counter(lst-ctn-kix_list_23-3, decimal) "." counter(lst-ctn-kix_list_23-4, decimal) ". "
    }

    .lst-kix_list_23-5>li:before {
        content: "" counter(lst-ctn-kix_list_23-0, decimal) "." counter(lst-ctn-kix_list_23-1, decimal) "." counter(lst-ctn-kix_list_23-2, decimal) "." counter(lst-ctn-kix_list_23-3, decimal) "." counter(lst-ctn-kix_list_23-4, decimal) "." counter(lst-ctn-kix_list_23-5, decimal) ". "
    }

    .lst-kix_list_22-2>li:before {
        content: "" counter(lst-ctn-kix_list_22-2, lower-roman) ") "
    }

    .lst-kix_list_22-6>li:before {
        content: "" counter(lst-ctn-kix_list_22-6, decimal) ". "
    }

    .lst-kix_list_8-6>li {
        counter-increment: lst-ctn-kix_list_8-6
    }

    .lst-kix_list_22-0>li:before {
        content: "" counter(lst-ctn-kix_list_22-0, decimal) ") "
    }

    .lst-kix_list_22-8>li:before {
        content: "" counter(lst-ctn-kix_list_22-8, lower-roman) ". "
    }

    ol.lst-kix_list_4-6.start {
        counter-reset: lst-ctn-kix_list_4-6 0
    }

    ol.lst-kix_list_9-7 {
        list-style-type: none
    }

    ol.lst-kix_list_9-8 {
        list-style-type: none
    }

    ol.lst-kix_list_22-5.start {
        counter-reset: lst-ctn-kix_list_22-5 0
    }

    ol.lst-kix_list_3-0.start {
        counter-reset: lst-ctn-kix_list_3-0 0
    }

    ol.lst-kix_list_9-3 {
        list-style-type: none
    }

    ol.lst-kix_list_9-4 {
        list-style-type: none
    }

    .lst-kix_list_5-7>li {
        counter-increment: lst-ctn-kix_list_5-7
    }

    ol.lst-kix_list_9-5 {
        list-style-type: none
    }

    ol.lst-kix_list_9-6 {
        list-style-type: none
    }

    ol.lst-kix_list_9-0 {
        list-style-type: none
    }

    ol.lst-kix_list_9-1 {
        list-style-type: none
    }

    ol.lst-kix_list_9-2 {
        list-style-type: none
    }

    .lst-kix_list_22-4>li:before {
        content: "(" counter(lst-ctn-kix_list_22-4, lower-latin) ") "
    }

    .lst-kix_list_3-5>li {
        counter-increment: lst-ctn-kix_list_3-5
    }

    ol.lst-kix_list_1-1.start {
        counter-reset: lst-ctn-kix_list_1-1 0
    }

    ol.lst-kix_list_18-3.start {
        counter-reset: lst-ctn-kix_list_18-3 0
    }

    ol.lst-kix_list_18-7 {
        list-style-type: none
    }

    ol.lst-kix_list_18-8 {
        list-style-type: none
    }

    ol.lst-kix_list_18-3 {
        list-style-type: none
    }

    ol.lst-kix_list_18-4 {
        list-style-type: none
    }

    .lst-kix_list_6-4>li {
        counter-increment: lst-ctn-kix_list_6-4
    }

    ol.lst-kix_list_18-5 {
        list-style-type: none
    }

    ol.lst-kix_list_18-6 {
        list-style-type: none
    }

    ol.lst-kix_list_18-0 {
        list-style-type: none
    }

    ol.lst-kix_list_18-1 {
        list-style-type: none
    }

    .lst-kix_list_9-3>li {
        counter-increment: lst-ctn-kix_list_9-3
    }

    ol.lst-kix_list_18-2 {
        list-style-type: none
    }

    ol.lst-kix_list_15-2.start {
        counter-reset: lst-ctn-kix_list_15-2 0
    }

    ol.lst-kix_list_2-8.start {
        counter-reset: lst-ctn-kix_list_2-8 0
    }

    ol.lst-kix_list_7-6.start {
        counter-reset: lst-ctn-kix_list_7-6 0
    }

    ol.lst-kix_list_15-3.start {
        counter-reset: lst-ctn-kix_list_15-3 0
    }

    ol.lst-kix_list_5-8.start {
        counter-reset: lst-ctn-kix_list_5-8 0
    }

    .lst-kix_list_1-3>li {
        counter-increment: lst-ctn-kix_list_1-3
    }

    ol.lst-kix_list_12-2.start {
        counter-reset: lst-ctn-kix_list_12-2 0
    }

    ol.lst-kix_list_6-0.start {
        counter-reset: lst-ctn-kix_list_6-0 0
    }

    .lst-kix_list_4-2>li {
        counter-increment: lst-ctn-kix_list_4-2
    }

    ol.lst-kix_list_3-1 {
        list-style-type: none
    }

    ol.lst-kix_list_3-2 {
        list-style-type: none
    }

    ol.lst-kix_list_3-3 {
        list-style-type: none
    }

    ol.lst-kix_list_3-4.start {
        counter-reset: lst-ctn-kix_list_3-4 0
    }

    .lst-kix_list_5-1>li {
        counter-increment: lst-ctn-kix_list_5-1
    }

    ol.lst-kix_list_3-4 {
        list-style-type: none
    }

    ol.lst-kix_list_19-0.start {
        counter-reset: lst-ctn-kix_list_19-0 0
    }

    ol.lst-kix_list_21-3.start {
        counter-reset: lst-ctn-kix_list_21-3 0
    }

    ol.lst-kix_list_3-0 {
        list-style-type: none
    }

    .lst-kix_list_7-1>li {
        counter-increment: lst-ctn-kix_list_7-1
    }

    .lst-kix_list_21-8>li:before {
        content: "" counter(lst-ctn-kix_list_21-8, lower-roman) ". "
    }

    .lst-kix_list_16-0>li {
        counter-increment: lst-ctn-kix_list_16-0
    }

    .lst-kix_list_8-0>li {
        counter-increment: lst-ctn-kix_list_8-0
    }

    .lst-kix_list_10-0>li {
        counter-increment: lst-ctn-kix_list_10-0
    }

    ol.lst-kix_list_18-2.start {
        counter-reset: lst-ctn-kix_list_18-2 0
    }

    ol.lst-kix_list_3-5 {
        list-style-type: none
    }

    ol.lst-kix_list_3-6 {
        list-style-type: none
    }

    ol.lst-kix_list_3-7 {
        list-style-type: none
    }

    ol.lst-kix_list_3-8 {
        list-style-type: none
    }

    .lst-kix_list_21-0>li:before {
        content: "" counter(lst-ctn-kix_list_21-0, decimal) ") "
    }

    .lst-kix_list_13-1>li {
        counter-increment: lst-ctn-kix_list_13-1
    }

    .lst-kix_list_21-1>li:before {
        content: "" counter(lst-ctn-kix_list_21-1, lower-latin) ") "
    }

    ol.lst-kix_list_15-8.start {
        counter-reset: lst-ctn-kix_list_15-8 0
    }

    .lst-kix_list_10-2>li {
        counter-increment: lst-ctn-kix_list_10-2
    }

    .lst-kix_list_21-5>li:before {
        content: "(" counter(lst-ctn-kix_list_21-5, lower-roman) ") "
    }

    .lst-kix_list_21-4>li:before {
        content: "(" counter(lst-ctn-kix_list_21-4, lower-latin) ") "
    }

    ol.lst-kix_list_7-2.start {
        counter-reset: lst-ctn-kix_list_7-2 0
    }

    ol.lst-kix_list_19-5.start {
        counter-reset: lst-ctn-kix_list_19-5 0
    }

    ol.lst-kix_list_12-5 {
        list-style-type: none
    }

    ol.lst-kix_list_12-6 {
        list-style-type: none
    }

    ol.lst-kix_list_12-7 {
        list-style-type: none
    }

    ol.lst-kix_list_12-8 {
        list-style-type: none
    }

    ol.lst-kix_list_12-1 {
        list-style-type: none
    }

    ol.lst-kix_list_12-2 {
        list-style-type: none
    }

    ol.lst-kix_list_12-3 {
        list-style-type: none
    }

    ol.lst-kix_list_12-4 {
        list-style-type: none
    }

    ol.lst-kix_list_12-0 {
        list-style-type: none
    }

    .lst-kix_list_21-0>li {
        counter-increment: lst-ctn-kix_list_21-0
    }

    ol.lst-kix_list_10-8.start {
        counter-reset: lst-ctn-kix_list_10-8 0
    }

    ol.lst-kix_list_7-1.start {
        counter-reset: lst-ctn-kix_list_7-1 0
    }

    ol.lst-kix_list_23-6 {
        list-style-type: none
    }

    ol.lst-kix_list_23-5 {
        list-style-type: none
    }

    ol.lst-kix_list_23-8 {
        list-style-type: none
    }

    ol.lst-kix_list_23-7 {
        list-style-type: none
    }

    ol.lst-kix_list_23-2 {
        list-style-type: none
    }

    .lst-kix_list_16-7>li {
        counter-increment: lst-ctn-kix_list_16-7
    }

    ol.lst-kix_list_21-4.start {
        counter-reset: lst-ctn-kix_list_21-4 0
    }

    ol.lst-kix_list_23-1 {
        list-style-type: none
    }

    ol.lst-kix_list_23-4 {
        list-style-type: none
    }

    ol.lst-kix_list_23-3 {
        list-style-type: none
    }

    ol.lst-kix_list_23-0 {
        list-style-type: none
    }

    ol.lst-kix_list_20-6.start {
        counter-reset: lst-ctn-kix_list_20-6 0
    }

    .lst-kix_list_13-8>li {
        counter-increment: lst-ctn-kix_list_13-8
    }

    .lst-kix_list_2-2>li {
        counter-increment: lst-ctn-kix_list_2-2
    }

    ol.lst-kix_list_4-7.start {
        counter-reset: lst-ctn-kix_list_4-7 0
    }

    .lst-kix_list_16-5>li {
        counter-increment: lst-ctn-kix_list_16-5
    }

    ol.lst-kix_list_5-0 {
        list-style-type: none
    }

    .lst-kix_list_3-7>li {
        counter-increment: lst-ctn-kix_list_3-7
    }

    ol.lst-kix_list_5-1 {
        list-style-type: none
    }

    ol.lst-kix_list_5-2 {
        list-style-type: none
    }

    .lst-kix_list_22-5>li:before {
        content: "(" counter(lst-ctn-kix_list_22-5, lower-roman) ") "
    }

    .lst-kix_list_21-2>li {
        counter-increment: lst-ctn-kix_list_21-2
    }

    .lst-kix_list_22-1>li:before {
        content: "" counter(lst-ctn-kix_list_22-1, lower-latin) ") "
    }

    .lst-kix_list_20-2>li {
        counter-increment: lst-ctn-kix_list_20-2
    }

    .lst-kix_list_6-6>li {
        counter-increment: lst-ctn-kix_list_6-6
    }

    ol.lst-kix_list_15-7.start {
        counter-reset: lst-ctn-kix_list_15-7 0
    }

    .lst-kix_list_23-3>li {
        counter-increment: lst-ctn-kix_list_23-3
    }

    .lst-kix_list_13-6>li {
        counter-increment: lst-ctn-kix_list_13-6
    }

    ol.lst-kix_list_14-6.start {
        counter-reset: lst-ctn-kix_list_14-6 0
    }

    ol.lst-kix_list_5-7 {
        list-style-type: none
    }

    ol.lst-kix_list_5-8 {
        list-style-type: none
    }

    ol.lst-kix_list_5-3 {
        list-style-type: none
    }

    .lst-kix_list_8-7>li {
        counter-increment: lst-ctn-kix_list_8-7
    }

    .lst-kix_list_19-6>li {
        counter-increment: lst-ctn-kix_list_19-6
    }

    ol.lst-kix_list_5-4 {
        list-style-type: none
    }

    ol.lst-kix_list_5-5 {
        list-style-type: none
    }

    ol.lst-kix_list_5-6 {
        list-style-type: none
    }

    .lst-kix_list_20-4>li {
        counter-increment: lst-ctn-kix_list_20-4
    }

    .lst-kix_list_9-5>li {
        counter-increment: lst-ctn-kix_list_9-5
    }

    .lst-kix_list_5-8>li {
        counter-increment: lst-ctn-kix_list_5-8
    }

    ol.lst-kix_list_19-4.start {
        counter-reset: lst-ctn-kix_list_19-4 0
    }

    ol.lst-kix_list_2-2.start {
        counter-reset: lst-ctn-kix_list_2-2 0
    }

    ol.lst-kix_list_20-1.start {
        counter-reset: lst-ctn-kix_list_20-1 0
    }

    .lst-kix_list_19-4>li {
        counter-increment: lst-ctn-kix_list_19-4
    }

    ol.lst-kix_list_14-7 {
        list-style-type: none
    }

    ol.lst-kix_list_14-8 {
        list-style-type: none
    }

    ol.lst-kix_list_14-3 {
        list-style-type: none
    }

    ol.lst-kix_list_21-8.start {
        counter-reset: lst-ctn-kix_list_21-8 0
    }

    ol.lst-kix_list_14-4 {
        list-style-type: none
    }

    ol.lst-kix_list_14-5 {
        list-style-type: none
    }

    ol.lst-kix_list_14-6 {
        list-style-type: none
    }

    .lst-kix_list_15-2>li {
        counter-increment: lst-ctn-kix_list_15-2
    }

    ol.lst-kix_list_14-0 {
        list-style-type: none
    }

    .lst-kix_list_22-5>li {
        counter-increment: lst-ctn-kix_list_22-5
    }

    ol.lst-kix_list_14-1 {
        list-style-type: none
    }

    ol.lst-kix_list_14-2 {
        list-style-type: none
    }

    .lst-kix_list_20-5>li:before {
        content: "(" counter(lst-ctn-kix_list_20-4, decimal) ")" counter(lst-ctn-kix_list_20-5, decimal) ". "
    }

    .lst-kix_list_20-1>li:before {
        content: "" counter(lst-ctn-kix_list_20-0, decimal) "." counter(lst-ctn-kix_list_20-1, decimal) ". "
    }

    .lst-kix_list_12-3>li {
        counter-increment: lst-ctn-kix_list_12-3
    }

    .lst-kix_list_17-3>li {
        counter-increment: lst-ctn-kix_list_17-3
    }

    ol.lst-kix_list_8-4.start {
        counter-reset: lst-ctn-kix_list_8-4 0
    }

    ol.lst-kix_list_3-5.start {
        counter-reset: lst-ctn-kix_list_3-5 0
    }

    .lst-kix_list_21-7>li {
        counter-increment: lst-ctn-kix_list_21-7
    }

    ol.lst-kix_list_13-0.start {
        counter-reset: lst-ctn-kix_list_13-0 0
    }

    .lst-kix_list_14-4>li {
        counter-increment: lst-ctn-kix_list_14-4
    }

    .lst-kix_list_10-7>li {
        counter-increment: lst-ctn-kix_list_10-7
    }

    .lst-kix_list_18-1>li {
        counter-increment: lst-ctn-kix_list_18-1
    }

    ol.lst-kix_list_8-3.start {
        counter-reset: lst-ctn-kix_list_8-3 0
    }

    .lst-kix_list_11-5>li {
        counter-increment: lst-ctn-kix_list_11-5
    }

    ol.lst-kix_list_14-5.start {
        counter-reset: lst-ctn-kix_list_14-5 0
    }

    ol.lst-kix_list_20-3.start {
        counter-reset: lst-ctn-kix_list_20-3 0
    }

    .lst-kix_list_4-1>li {
        counter-increment: lst-ctn-kix_list_4-1
    }

    .lst-kix_list_19-1>li:before {
        content: "" counter(lst-ctn-kix_list_19-0, decimal) "." counter(lst-ctn-kix_list_19-1, decimal) ". "
    }

    .lst-kix_list_19-4>li:before {
        content: "" counter(lst-ctn-kix_list_19-0, decimal) "." counter(lst-ctn-kix_list_19-1, decimal) "." counter(lst-ctn-kix_list_19-2, decimal) "." counter(lst-ctn-kix_list_19-3, decimal) "." counter(lst-ctn-kix_list_19-4, decimal) ". "
    }

    .lst-kix_list_19-3>li:before {
        content: "" counter(lst-ctn-kix_list_19-0, decimal) "." counter(lst-ctn-kix_list_19-1, decimal) "." counter(lst-ctn-kix_list_19-2, decimal) "." counter(lst-ctn-kix_list_19-3, decimal) ". "
    }

    .lst-kix_list_15-0>li {
        counter-increment: lst-ctn-kix_list_15-0
    }

    ol.lst-kix_list_6-6.start {
        counter-reset: lst-ctn-kix_list_6-6 0
    }

    .lst-kix_list_11-0>li {
        counter-increment: lst-ctn-kix_list_11-0
    }

    ol.lst-kix_list_1-5.start {
        counter-reset: lst-ctn-kix_list_1-5 0
    }

    ol.lst-kix_list_9-6.start {
        counter-reset: lst-ctn-kix_list_9-6 0
    }

    ol.lst-kix_list_16-3.start {
        counter-reset: lst-ctn-kix_list_16-3 0
    }

    ol.lst-kix_list_22-4.start {
        counter-reset: lst-ctn-kix_list_22-4 0
    }

    .lst-kix_list_22-7>li {
        counter-increment: lst-ctn-kix_list_22-7
    }

    ol.lst-kix_list_4-5.start {
        counter-reset: lst-ctn-kix_list_4-5 0
    }

    ol.lst-kix_list_11-2.start {
        counter-reset: lst-ctn-kix_list_11-2 0
    }

    .lst-kix_list_5-2>li {
        counter-increment: lst-ctn-kix_list_5-2
    }

    .lst-kix_list_19-6>li:before {
        content: "(" counter(lst-ctn-kix_list_19-4, decimal) ")" counter(lst-ctn-kix_list_19-5, decimal) "." counter(lst-ctn-kix_list_19-6, decimal) ". "
    }

    ol.lst-kix_list_8-7.start {
        counter-reset: lst-ctn-kix_list_8-7 0
    }

    .lst-kix_list_17-2>li {
        counter-increment: lst-ctn-kix_list_17-2
    }

    .lst-kix_list_20-5>li {
        counter-increment: lst-ctn-kix_list_20-5
    }

    .lst-kix_list_22-0>li {
        counter-increment: lst-ctn-kix_list_22-0
    }

    .lst-kix_list_18-3>li {
        counter-increment: lst-ctn-kix_list_18-3
    }

    .lst-kix_list_21-6>li {
        counter-increment: lst-ctn-kix_list_21-6
    }

    .lst-kix_list_10-3>li {
        counter-increment: lst-ctn-kix_list_10-3
    }

    ol.lst-kix_list_1-0.start {
        counter-reset: lst-ctn-kix_list_1-0 0
    }

    .lst-kix_list_18-0>li:before {
        content: "" counter(lst-ctn-kix_list_18-0, decimal) ". "
    }

    ol.lst-kix_list_13-3.start {
        counter-reset: lst-ctn-kix_list_13-3 0
    }

    .lst-kix_list_3-0>li {
        counter-increment: lst-ctn-kix_list_3-0
    }

    .lst-kix_list_18-2>li:before {
        content: "" counter(lst-ctn-kix_list_18-0, decimal) "." counter(lst-ctn-kix_list_18-1, decimal) "." counter(lst-ctn-kix_list_18-2, decimal) ". "
    }

    ol.lst-kix_list_4-0.start {
        counter-reset: lst-ctn-kix_list_4-0 0
    }

    ol.lst-kix_list_11-7.start {
        counter-reset: lst-ctn-kix_list_11-7 0
    }

    ol.lst-kix_list_14-2.start {
        counter-reset: lst-ctn-kix_list_14-2 0
    }

    .lst-kix_list_16-1>li {
        counter-increment: lst-ctn-kix_list_16-1
    }

    .lst-kix_list_17-5>li {
        counter-increment: lst-ctn-kix_list_17-5
    }

    ol.lst-kix_list_9-4.start {
        counter-reset: lst-ctn-kix_list_9-4 0
    }

    .lst-kix_list_21-3>li {
        counter-increment: lst-ctn-kix_list_21-3
    }

    .lst-kix_list_18-8>li:before {
        content: "(" counter(lst-ctn-kix_list_18-4, decimal) ")" counter(lst-ctn-kix_list_18-5, decimal) "." counter(lst-ctn-kix_list_18-6, decimal) "." counter(lst-ctn-kix_list_18-7, decimal) "." counter(lst-ctn-kix_list_18-8, decimal) ". "
    }

    .lst-kix_list_17-6>li {
        counter-increment: lst-ctn-kix_list_17-6
    }

    ol.lst-kix_list_4-3.start {
        counter-reset: lst-ctn-kix_list_4-3 0
    }

    .lst-kix_list_10-7>li:before {
        content: "" counter(lst-ctn-kix_list_10-7, lower-latin) ". "
    }

    .lst-kix_list_7-8>li {
        counter-increment: lst-ctn-kix_list_7-8
    }

    .lst-kix_list_20-1>li {
        counter-increment: lst-ctn-kix_list_20-1
    }

    .lst-kix_list_10-5>li:before {
        content: "(" counter(lst-ctn-kix_list_10-5, lower-roman) ") "
    }

    ol.lst-kix_list_13-5.start {
        counter-reset: lst-ctn-kix_list_13-5 0
    }

    ol.lst-kix_list_18-6.start {
        counter-reset: lst-ctn-kix_list_18-6 0
    }

    ol.lst-kix_list_20-8 {
        list-style-type: none
    }

    ol.lst-kix_list_13-8.start {
        counter-reset: lst-ctn-kix_list_13-8 0
    }

    ol.lst-kix_list_20-5 {
        list-style-type: none
    }

    ol.lst-kix_list_20-4 {
        list-style-type: none
    }

    ol.lst-kix_list_20-7 {
        list-style-type: none
    }

    .lst-kix_list_11-7>li {
        counter-increment: lst-ctn-kix_list_11-7
    }

    ol.lst-kix_list_20-6 {
        list-style-type: none
    }

    .lst-kix_list_9-2>li:before {
        content: "" counter(lst-ctn-kix_list_9-2, lower-roman) ") "
    }

    ol.lst-kix_list_20-1 {
        list-style-type: none
    }

    ol.lst-kix_list_20-0 {
        list-style-type: none
    }

    ol.lst-kix_list_20-3 {
        list-style-type: none
    }

    ol.lst-kix_list_20-2 {
        list-style-type: none
    }

    ol.lst-kix_list_14-0.start {
        counter-reset: lst-ctn-kix_list_14-0 0
    }

    .lst-kix_list_12-5>li {
        counter-increment: lst-ctn-kix_list_12-5
    }

    .lst-kix_list_5-5>li {
        counter-increment: lst-ctn-kix_list_5-5
    }

    .lst-kix_list_9-0>li:before {
        content: "" counter(lst-ctn-kix_list_9-0, decimal) ") "
    }

    .lst-kix_list_16-8>li {
        counter-increment: lst-ctn-kix_list_16-8
    }

    .lst-kix_list_23-5>li {
        counter-increment: lst-ctn-kix_list_23-5
    }

    .lst-kix_list_11-3>li:before {
        content: "(" counter(lst-ctn-kix_list_11-3, decimal) ") "
    }

    .lst-kix_list_6-3>li {
        counter-increment: lst-ctn-kix_list_6-3
    }

    ol.lst-kix_list_18-4.start {
        counter-reset: lst-ctn-kix_list_18-4 0
    }

    ol.lst-kix_list_1-3.start {
        counter-reset: lst-ctn-kix_list_1-3 0
    }

    ol.lst-kix_list_1-2.start {
        counter-reset: lst-ctn-kix_list_1-2 0
    }

    .lst-kix_list_20-4>li:before {
        content: "" counter(lst-ctn-kix_list_20-0, decimal) "." counter(lst-ctn-kix_list_20-1, decimal) "." counter(lst-ctn-kix_list_20-2, decimal) "." counter(lst-ctn-kix_list_20-3, decimal) "." counter(lst-ctn-kix_list_20-4, decimal) ". "
    }

    ol.lst-kix_list_22-7.start {
        counter-reset: lst-ctn-kix_list_22-7 0
    }

    ol.lst-kix_list_6-1.start {
        counter-reset: lst-ctn-kix_list_6-1 0
    }

    .lst-kix_list_20-2>li:before {
        content: "" counter(lst-ctn-kix_list_20-0, decimal) "." counter(lst-ctn-kix_list_20-1, decimal) "." counter(lst-ctn-kix_list_20-2, decimal) ". "
    }

    .lst-kix_list_9-8>li:before {
        content: "" counter(lst-ctn-kix_list_9-8, lower-roman) ". "
    }

    ol.lst-kix_list_16-8.start {
        counter-reset: lst-ctn-kix_list_16-8 0
    }

    .lst-kix_list_4-8>li {
        counter-increment: lst-ctn-kix_list_4-8
    }

    .lst-kix_list_1-7>li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) "." counter(lst-ctn-kix_list_1-3, decimal) "." counter(lst-ctn-kix_list_1-4, decimal) "." counter(lst-ctn-kix_list_1-5, decimal) "." counter(lst-ctn-kix_list_1-6, decimal) "." counter(lst-ctn-kix_list_1-7, decimal) ". "
    }

    .lst-kix_list_1-5>li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) "." counter(lst-ctn-kix_list_1-3, decimal) "." counter(lst-ctn-kix_list_1-4, decimal) "." counter(lst-ctn-kix_list_1-5, decimal) ". "
    }

    ol.lst-kix_list_9-1.start {
        counter-reset: lst-ctn-kix_list_9-1 0
    }

    .lst-kix_list_5-6>li {
        counter-increment: lst-ctn-kix_list_5-6
    }

    ol.lst-kix_list_22-6.start {
        counter-reset: lst-ctn-kix_list_22-6 0
    }

    .lst-kix_list_2-1>li:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-1, decimal) ". "
    }

    .lst-kix_list_19-8>li {
        counter-increment: lst-ctn-kix_list_19-8
    }

    .lst-kix_list_2-3>li:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) "." counter(lst-ctn-kix_list_2-3, decimal) ". "
    }

    .lst-kix_list_11-8>li {
        counter-increment: lst-ctn-kix_list_11-8
    }

    .lst-kix_list_20-8>li {
        counter-increment: lst-ctn-kix_list_20-8
    }

    .lst-kix_list_9-1>li {
        counter-increment: lst-ctn-kix_list_9-1
    }

    .lst-kix_list_3-2>li:before {
        content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ") "
    }

    .lst-kix_list_8-1>li:before {
        content: "" counter(lst-ctn-kix_list_8-0, decimal) "." counter(lst-ctn-kix_list_8-1, decimal) ". "
    }

    ol.lst-kix_list_1-8.start {
        counter-reset: lst-ctn-kix_list_1-8 0
    }

    .lst-kix_list_6-0>li {
        counter-increment: lst-ctn-kix_list_6-0
    }

    .lst-kix_list_3-5>li:before {
        content: "(" counter(lst-ctn-kix_list_3-5, lower-roman) ") "
    }

    .lst-kix_list_18-0>li {
        counter-increment: lst-ctn-kix_list_18-0
    }

    ol.lst-kix_list_11-5.start {
        counter-reset: lst-ctn-kix_list_11-5 0
    }

    .lst-kix_list_11-1>li {
        counter-increment: lst-ctn-kix_list_11-1
    }

    .lst-kix_list_8-6>li:before {
        content: "(" counter(lst-ctn-kix_list_8-4, decimal) ")" counter(lst-ctn-kix_list_8-5, decimal) "." counter(lst-ctn-kix_list_8-6, decimal) ". "
    }

    .lst-kix_list_21-6>li:before {
        content: "" counter(lst-ctn-kix_list_21-6, decimal) ". "
    }

    ol.lst-kix_list_16-6.start {
        counter-reset: lst-ctn-kix_list_16-6 0
    }

    ol.lst-kix_list_22-1.start {
        counter-reset: lst-ctn-kix_list_22-1 0
    }

    ol.lst-kix_list_4-2.start {
        counter-reset: lst-ctn-kix_list_4-2 0
    }

    ol.lst-kix_list_16-0.start {
        counter-reset: lst-ctn-kix_list_16-0 0
    }

    .lst-kix_list_21-3>li:before {
        content: "(" counter(lst-ctn-kix_list_21-3, decimal) ") "
    }

    ol.lst-kix_list_18-7.start {
        counter-reset: lst-ctn-kix_list_18-7 0
    }

    ol.lst-kix_list_11-6.start {
        counter-reset: lst-ctn-kix_list_11-6 0
    }

    .lst-kix_list_4-4>li {
        counter-increment: lst-ctn-kix_list_4-4
    }

    ol.lst-kix_list_6-4.start {
        counter-reset: lst-ctn-kix_list_6-4 0
    }

    .lst-kix_list_17-1>li:before {
        content: "" counter(lst-ctn-kix_list_17-0, decimal) "." counter(lst-ctn-kix_list_17-1, decimal) ". "
    }

    ol.lst-kix_list_4-1.start {
        counter-reset: lst-ctn-kix_list_4-1 0
    }

    .lst-kix_list_16-2>li:before {
        content: "" counter(lst-ctn-kix_list_16-0, decimal) "." counter(lst-ctn-kix_list_16-1, decimal) "." counter(lst-ctn-kix_list_16-2, decimal) ". "
    }

    .lst-kix_list_16-5>li:before {
        content: "(" counter(lst-ctn-kix_list_16-4, decimal) ")" counter(lst-ctn-kix_list_16-5, decimal) ". "
    }

    .lst-kix_list_15-3>li {
        counter-increment: lst-ctn-kix_list_15-3
    }

    ol.lst-kix_list_22-2.start {
        counter-reset: lst-ctn-kix_list_22-2 0
    }

    ol.lst-kix_list_11-0.start {
        counter-reset: lst-ctn-kix_list_11-0 0
    }

    ol.lst-kix_list_18-8.start {
        counter-reset: lst-ctn-kix_list_18-8 0
    }

    .lst-kix_list_3-3>li {
        counter-increment: lst-ctn-kix_list_3-3
    }

    .lst-kix_list_16-4>li {
        counter-increment: lst-ctn-kix_list_16-4
    }

    ol.lst-kix_list_6-3.start {
        counter-reset: lst-ctn-kix_list_6-3 0
    }

    ol.lst-kix_list_16-5.start {
        counter-reset: lst-ctn-kix_list_16-5 0
    }

    .lst-kix_list_17-6>li:before {
        content: "(" counter(lst-ctn-kix_list_17-4, decimal) ")" counter(lst-ctn-kix_list_17-5, decimal) "." counter(lst-ctn-kix_list_17-6, decimal) ". "
    }

    .lst-kix_list_2-6>li:before {
        content: " "
    }

    ol.lst-kix_list_16-2.start {
        counter-reset: lst-ctn-kix_list_16-2 0
    }

    .lst-kix_list_14-5>li {
        counter-increment: lst-ctn-kix_list_14-5
    }

    .lst-kix_list_7-5>li:before {
        content: "(" counter(lst-ctn-kix_list_7-4, decimal) ")" counter(lst-ctn-kix_list_7-5, decimal) ". "
    }

    .lst-kix_list_19-5>li {
        counter-increment: lst-ctn-kix_list_19-5
    }

    .lst-kix_list_22-7>li:before {
        content: "" counter(lst-ctn-kix_list_22-7, lower-latin) ". "
    }

    .lst-kix_list_23-2>li {
        counter-increment: lst-ctn-kix_list_23-2
    }

    ol.lst-kix_list_11-1.start {
        counter-reset: lst-ctn-kix_list_11-1 0
    }

    .lst-kix_list_18-5>li:before {
        content: "(" counter(lst-ctn-kix_list_18-4, decimal) ")" counter(lst-ctn-kix_list_18-5, decimal) ". "
    }

    .lst-kix_list_13-6>li:before {
        content: "(" counter(lst-ctn-kix_list_13-4, decimal) ")" counter(lst-ctn-kix_list_13-5, decimal) "." counter(lst-ctn-kix_list_13-6, decimal) ". "
    }

    .lst-kix_list_6-7>li {
        counter-increment: lst-ctn-kix_list_6-7
    }

    .lst-kix_list_10-6>li {
        counter-increment: lst-ctn-kix_list_10-6
    }

    .lst-kix_list_1-7>li {
        counter-increment: lst-ctn-kix_list_1-7
    }

    .lst-kix_list_7-5>li {
        counter-increment: lst-ctn-kix_list_7-5
    }

    .lst-kix_list_15-6>li:before {
        content: "" counter(lst-ctn-kix_list_15-6, decimal) ". "
    }

    .lst-kix_list_11-4>li {
        counter-increment: lst-ctn-kix_list_11-4
    }

    .lst-kix_list_22-4>li {
        counter-increment: lst-ctn-kix_list_22-4
    }

    ol.lst-kix_list_6-8.start {
        counter-reset: lst-ctn-kix_list_6-8 0
    }

    .lst-kix_list_10-2>li:before {
        content: "" counter(lst-ctn-kix_list_10-2, lower-roman) ") "
    }

    .lst-kix_list_13-7>li {
        counter-increment: lst-ctn-kix_list_13-7
    }

    ol.lst-kix_list_1-7.start {
        counter-reset: lst-ctn-kix_list_1-7 0
    }

    .lst-kix_list_20-7>li:before {
        content: "(" counter(lst-ctn-kix_list_20-4, decimal) ")" counter(lst-ctn-kix_list_20-5, decimal) "." counter(lst-ctn-kix_list_20-6, decimal) "." counter(lst-ctn-kix_list_20-7, decimal) ". "
    }

    ol.lst-kix_list_6-5.start {
        counter-reset: lst-ctn-kix_list_6-5 0
    }

    .lst-kix_list_4-6>li:before {
        content: "" counter(lst-ctn-kix_list_4-6, decimal) ". "
    }

    ol.lst-kix_list_6-7.start {
        counter-reset: lst-ctn-kix_list_6-7 0
    }

    .lst-kix_list_12-2>li {
        counter-increment: lst-ctn-kix_list_12-2
    }

    .lst-kix_list_9-5>li:before {
        content: "(" counter(lst-ctn-kix_list_9-5, lower-roman) ") "
    }

    ol.lst-kix_list_22-0.start {
        counter-reset: lst-ctn-kix_list_22-0 0
    }

    .lst-kix_list_12-2>li:before {
        content: "" counter(lst-ctn-kix_list_12-0, decimal) "." counter(lst-ctn-kix_list_12-1, decimal) "." counter(lst-ctn-kix_list_12-2, decimal) ". "
    }

    .lst-kix_list_11-6>li:before {
        content: "" counter(lst-ctn-kix_list_11-6, decimal) ". "
    }

    ol.lst-kix_list_11-3.start {
        counter-reset: lst-ctn-kix_list_11-3 0
    }

    .lst-kix_list_1-2>li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) ". "
    }

    .lst-kix_list_1-0>li {
        counter-increment: lst-ctn-kix_list_1-0
    }

    ol.lst-kix_list_16-1.start {
        counter-reset: lst-ctn-kix_list_16-1 0
    }

    li.li-bullet-0:before {
        margin-left: -28.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 28.4pt
    }

    .lst-kix_list_18-7>li {
        counter-increment: lst-ctn-kix_list_18-7
    }

    ol.lst-kix_list_11-4.start {
        counter-reset: lst-ctn-kix_list_11-4 0
    }

    .lst-kix_list_14-1>li:before {
        content: "" counter(lst-ctn-kix_list_14-0, decimal) "." counter(lst-ctn-kix_list_14-1, decimal) ". "
    }

    .lst-kix_list_14-3>li:before {
        content: "" counter(lst-ctn-kix_list_14-0, decimal) "." counter(lst-ctn-kix_list_14-1, decimal) "." counter(lst-ctn-kix_list_14-2, decimal) "." counter(lst-ctn-kix_list_14-3, decimal) ". "
    }

    ol.lst-kix_list_15-6 {
        list-style-type: none
    }

    ol.lst-kix_list_15-7 {
        list-style-type: none
    }

    ol.lst-kix_list_15-8 {
        list-style-type: none
    }

    .lst-kix_list_14-0>li:before {
        content: "" counter(lst-ctn-kix_list_14-0, decimal) ". "
    }

    .lst-kix_list_14-4>li:before {
        content: "(" counter(lst-ctn-kix_list_14-4, decimal) ") "
    }

    ol.lst-kix_list_15-2 {
        list-style-type: none
    }

    ol.lst-kix_list_15-3 {
        list-style-type: none
    }

    ol.lst-kix_list_15-4 {
        list-style-type: none
    }

    ol.lst-kix_list_18-5.start {
        counter-reset: lst-ctn-kix_list_18-5 0
    }

    .lst-kix_list_6-1>li {
        counter-increment: lst-ctn-kix_list_6-1
    }

    .lst-kix_list_14-5>li:before {
        content: "(" counter(lst-ctn-kix_list_14-4, decimal) ")" counter(lst-ctn-kix_list_14-5, decimal) ". "
    }

    .lst-kix_list_14-7>li:before {
        content: "(" counter(lst-ctn-kix_list_14-4, decimal) ")" counter(lst-ctn-kix_list_14-5, decimal) "." counter(lst-ctn-kix_list_14-6, decimal) "." counter(lst-ctn-kix_list_14-7, decimal) ". "
    }

    ol.lst-kix_list_15-5 {
        list-style-type: none
    }

    ol.lst-kix_list_15-0 {
        list-style-type: none
    }

    .lst-kix_list_14-6>li:before {
        content: "(" counter(lst-ctn-kix_list_14-4, decimal) ")" counter(lst-ctn-kix_list_14-5, decimal) "." counter(lst-ctn-kix_list_14-6, decimal) ". "
    }

    ol.lst-kix_list_15-1 {
        list-style-type: none
    }

    ol.lst-kix_list_7-4.start {
        counter-reset: lst-ctn-kix_list_7-4 0
    }

    .lst-kix_list_17-0>li {
        counter-increment: lst-ctn-kix_list_17-0
    }

    .lst-kix_list_9-0>li {
        counter-increment: lst-ctn-kix_list_9-0
    }

    ol.lst-kix_list_11-8.start {
        counter-reset: lst-ctn-kix_list_11-8 0
    }

    .lst-kix_list_14-2>li:before {
        content: "" counter(lst-ctn-kix_list_14-0, decimal) "." counter(lst-ctn-kix_list_14-1, decimal) "." counter(lst-ctn-kix_list_14-2, decimal) ". "
    }

    .lst-kix_list_20-7>li {
        counter-increment: lst-ctn-kix_list_20-7
    }

    ol.lst-kix_list_12-0.start {
        counter-reset: lst-ctn-kix_list_12-0 0
    }

    ol.lst-kix_list_21-6.start {
        counter-reset: lst-ctn-kix_list_21-6 0
    }

    ol.lst-kix_list_3-7.start {
        counter-reset: lst-ctn-kix_list_3-7 0
    }

    .lst-kix_list_14-8>li:before {
        content: "(" counter(lst-ctn-kix_list_14-4, decimal) ")" counter(lst-ctn-kix_list_14-5, decimal) "." counter(lst-ctn-kix_list_14-6, decimal) "." counter(lst-ctn-kix_list_14-7, decimal) "." counter(lst-ctn-kix_list_14-8, decimal) ". "
    }

    .lst-kix_list_3-2>li {
        counter-increment: lst-ctn-kix_list_3-2
    }

    ol.lst-kix_list_15-5.start {
        counter-reset: lst-ctn-kix_list_15-5 0
    }

    .lst-kix_list_5-0>li:before {
        content: "" counter(lst-ctn-kix_list_5-0, decimal) ". "
    }

    ol.lst-kix_list_6-0 {
        list-style-type: none
    }

    ol.lst-kix_list_6-1 {
        list-style-type: none
    }

    .lst-kix_list_14-8>li {
        counter-increment: lst-ctn-kix_list_14-8
    }

    ol.lst-kix_list_22-3.start {
        counter-reset: lst-ctn-kix_list_22-3 0
    }

    .lst-kix_list_5-4>li {
        counter-increment: lst-ctn-kix_list_5-4
    }

    .lst-kix_list_5-3>li:before {
        content: "" counter(lst-ctn-kix_list_5-0, decimal) "." counter(lst-ctn-kix_list_5-1, decimal) "." counter(lst-ctn-kix_list_5-2, decimal) "." counter(lst-ctn-kix_list_5-3, decimal) ". "
    }

    .lst-kix_list_5-2>li:before {
        content: "" counter(lst-ctn-kix_list_5-0, decimal) "." counter(lst-ctn-kix_list_5-1, decimal) "." counter(lst-ctn-kix_list_5-2, decimal) ". "
    }

    .lst-kix_list_8-3>li {
        counter-increment: lst-ctn-kix_list_8-3
    }

    .lst-kix_list_5-1>li:before {
        content: "" counter(lst-ctn-kix_list_5-0, decimal) "." counter(lst-ctn-kix_list_5-1, decimal) ". "
    }

    ol.lst-kix_list_18-0.start {
        counter-reset: lst-ctn-kix_list_18-0 0
    }

    .lst-kix_list_5-7>li:before {
        content: "(" counter(lst-ctn-kix_list_5-4, decimal) ")" counter(lst-ctn-kix_list_5-5, decimal) "." counter(lst-ctn-kix_list_5-6, decimal) "." counter(lst-ctn-kix_list_5-7, decimal) ". "
    }

    .lst-kix_list_5-6>li:before {
        content: "(" counter(lst-ctn-kix_list_5-4, decimal) ")" counter(lst-ctn-kix_list_5-5, decimal) "." counter(lst-ctn-kix_list_5-6, decimal) ". "
    }

    .lst-kix_list_5-8>li:before {
        content: "(" counter(lst-ctn-kix_list_5-4, decimal) ")" counter(lst-ctn-kix_list_5-5, decimal) "." counter(lst-ctn-kix_list_5-6, decimal) "." counter(lst-ctn-kix_list_5-7, decimal) "." counter(lst-ctn-kix_list_5-8, decimal) ". "
    }

    ol.lst-kix_list_6-6 {
        list-style-type: none
    }

    ol.lst-kix_list_6-7 {
        list-style-type: none
    }

    .lst-kix_list_5-4>li:before {
        content: "(" counter(lst-ctn-kix_list_5-4, decimal) ") "
    }

    ol.lst-kix_list_6-8 {
        list-style-type: none
    }

    .lst-kix_list_5-5>li:before {
        content: "(" counter(lst-ctn-kix_list_5-4, decimal) ")" counter(lst-ctn-kix_list_5-5, decimal) ". "
    }

    ol.lst-kix_list_6-2 {
        list-style-type: none
    }

    ol.lst-kix_list_6-3 {
        list-style-type: none
    }

    ol.lst-kix_list_6-4 {
        list-style-type: none
    }

    ol.lst-kix_list_6-5 {
        list-style-type: none
    }

    ol.lst-kix_list_12-5.start {
        counter-reset: lst-ctn-kix_list_12-5 0
    }

    .lst-kix_list_6-1>li:before {
        content: "" counter(lst-ctn-kix_list_6-0, decimal) "." counter(lst-ctn-kix_list_6-1, decimal) ". "
    }

    .lst-kix_list_6-3>li:before {
        content: "" counter(lst-ctn-kix_list_6-0, decimal) "." counter(lst-ctn-kix_list_6-1, decimal) "." counter(lst-ctn-kix_list_6-2, decimal) "." counter(lst-ctn-kix_list_6-3, decimal) ". "
    }

    .lst-kix_list_6-8>li {
        counter-increment: lst-ctn-kix_list_6-8
    }

    .lst-kix_list_6-0>li:before {
        content: "" counter(lst-ctn-kix_list_6-0, decimal) ". "
    }

    .lst-kix_list_6-4>li:before {
        content: "(" counter(lst-ctn-kix_list_6-4, decimal) ") "
    }

    ol.lst-kix_list_14-8.start {
        counter-reset: lst-ctn-kix_list_14-8 0
    }

    .lst-kix_list_6-2>li:before {
        content: "" counter(lst-ctn-kix_list_6-0, decimal) "." counter(lst-ctn-kix_list_6-1, decimal) "." counter(lst-ctn-kix_list_6-2, decimal) ". "
    }

    ol.lst-kix_list_15-0.start {
        counter-reset: lst-ctn-kix_list_15-0 0
    }

    .lst-kix_list_2-5>li {
        counter-increment: lst-ctn-kix_list_2-5
    }

    ol.lst-kix_list_3-2.start {
        counter-reset: lst-ctn-kix_list_3-2 0
    }

    .lst-kix_list_6-8>li:before {
        content: "(" counter(lst-ctn-kix_list_6-4, decimal) ")" counter(lst-ctn-kix_list_6-5, decimal) "." counter(lst-ctn-kix_list_6-6, decimal) "." counter(lst-ctn-kix_list_6-7, decimal) "." counter(lst-ctn-kix_list_6-8, decimal) ". "
    }

    .lst-kix_list_6-5>li:before {
        content: "(" counter(lst-ctn-kix_list_6-4, decimal) ")" counter(lst-ctn-kix_list_6-5, decimal) ". "
    }

    .lst-kix_list_6-7>li:before {
        content: "(" counter(lst-ctn-kix_list_6-4, decimal) ")" counter(lst-ctn-kix_list_6-5, decimal) "." counter(lst-ctn-kix_list_6-6, decimal) "." counter(lst-ctn-kix_list_6-7, decimal) ". "
    }

    .lst-kix_list_6-6>li:before {
        content: "(" counter(lst-ctn-kix_list_6-4, decimal) ")" counter(lst-ctn-kix_list_6-5, decimal) "." counter(lst-ctn-kix_list_6-6, decimal) ". "
    }

    ol.lst-kix_list_17-8 {
        list-style-type: none
    }

    ol.lst-kix_list_10-6.start {
        counter-reset: lst-ctn-kix_list_10-6 0
    }

    .lst-kix_list_7-4>li:before {
        content: "(" counter(lst-ctn-kix_list_7-4, decimal) ") "
    }

    .lst-kix_list_7-6>li:before {
        content: "(" counter(lst-ctn-kix_list_7-4, decimal) ")" counter(lst-ctn-kix_list_7-5, decimal) "." counter(lst-ctn-kix_list_7-6, decimal) ". "
    }

    ol.lst-kix_list_17-4 {
        list-style-type: none
    }

    .lst-kix_list_18-5>li {
        counter-increment: lst-ctn-kix_list_18-5
    }

    ol.lst-kix_list_19-7.start {
        counter-reset: lst-ctn-kix_list_19-7 0
    }

    ol.lst-kix_list_17-5 {
        list-style-type: none
    }

    ol.lst-kix_list_6-2.start {
        counter-reset: lst-ctn-kix_list_6-2 0
    }

    .lst-kix_list_15-5>li {
        counter-increment: lst-ctn-kix_list_15-5
    }

    ol.lst-kix_list_17-6 {
        list-style-type: none
    }

    ol.lst-kix_list_17-7 {
        list-style-type: none
    }

    ol.lst-kix_list_17-0 {
        list-style-type: none
    }

    .lst-kix_list_22-2>li {
        counter-increment: lst-ctn-kix_list_22-2
    }

    ol.lst-kix_list_17-1 {
        list-style-type: none
    }

    ol.lst-kix_list_17-2 {
        list-style-type: none
    }

    .lst-kix_list_7-2>li:before {
        content: "" counter(lst-ctn-kix_list_7-0, decimal) "." counter(lst-ctn-kix_list_7-1, decimal) "." counter(lst-ctn-kix_list_7-2, decimal) ". "
    }

    ol.lst-kix_list_17-3 {
        list-style-type: none
    }

    .lst-kix_list_7-6>li {
        counter-increment: lst-ctn-kix_list_7-6
    }

    ol.lst-kix_list_22-8.start {
        counter-reset: lst-ctn-kix_list_22-8 0
    }

    .lst-kix_list_12-6>li {
        counter-increment: lst-ctn-kix_list_12-6
    }

    .lst-kix_list_13-7>li:before {
        content: "(" counter(lst-ctn-kix_list_13-4, decimal) ")" counter(lst-ctn-kix_list_13-5, decimal) "." counter(lst-ctn-kix_list_13-6, decimal) "." counter(lst-ctn-kix_list_13-7, decimal) ". "
    }

    .lst-kix_list_7-8>li:before {
        content: "(" counter(lst-ctn-kix_list_7-4, decimal) ")" counter(lst-ctn-kix_list_7-5, decimal) "." counter(lst-ctn-kix_list_7-6, decimal) "." counter(lst-ctn-kix_list_7-7, decimal) "." counter(lst-ctn-kix_list_7-8, decimal) ". "
    }

    ol.lst-kix_list_23-0.start {
        counter-reset: lst-ctn-kix_list_23-0 0
    }

    .lst-kix_list_15-6>li {
        counter-increment: lst-ctn-kix_list_15-6
    }

    .lst-kix_list_4-7>li {
        counter-increment: lst-ctn-kix_list_4-7
    }

    ol.lst-kix_list_2-5.start {
        counter-reset: lst-ctn-kix_list_2-5 0
    }

    .lst-kix_list_15-5>li:before {
        content: "(" counter(lst-ctn-kix_list_15-5, lower-roman) ") "
    }

    .lst-kix_list_9-8>li {
        counter-increment: lst-ctn-kix_list_9-8
    }

    .lst-kix_list_13-4>li {
        counter-increment: lst-ctn-kix_list_13-4
    }

    .lst-kix_list_4-1>li:before {
        content: "" counter(lst-ctn-kix_list_4-1, lower-latin) ") "
    }

    .lst-kix_list_15-7>li:before {
        content: "" counter(lst-ctn-kix_list_15-7, lower-latin) ". "
    }

    .lst-kix_list_17-7>li {
        counter-increment: lst-ctn-kix_list_17-7
    }

    .lst-kix_list_4-3>li:before {
        content: "(" counter(lst-ctn-kix_list_4-3, decimal) ") "
    }

    .lst-kix_list_4-5>li:before {
        content: "(" counter(lst-ctn-kix_list_4-5, lower-roman) ") "
    }

    .lst-kix_list_1-8>li {
        counter-increment: lst-ctn-kix_list_1-8
    }

    .lst-kix_list_10-5>li {
        counter-increment: lst-ctn-kix_list_10-5
    }

    .lst-kix_list_15-1>li:before {
        content: "" counter(lst-ctn-kix_list_15-1, lower-latin) ") "
    }

    ol.lst-kix_list_1-4.start {
        counter-reset: lst-ctn-kix_list_1-4 0
    }

    .lst-kix_list_15-3>li:before {
        content: "(" counter(lst-ctn-kix_list_15-3, decimal) ") "
    }

    .lst-kix_list_22-1>li {
        counter-increment: lst-ctn-kix_list_22-1
    }

    ol.lst-kix_list_4-4.start {
        counter-reset: lst-ctn-kix_list_4-4 0
    }

    .lst-kix_list_16-2>li {
        counter-increment: lst-ctn-kix_list_16-2
    }

    ol.lst-kix_list_9-2.start {
        counter-reset: lst-ctn-kix_list_9-2 0
    }

    .lst-kix_list_20-0>li {
        counter-increment: lst-ctn-kix_list_20-0
    }

    ol.lst-kix_list_16-7.start {
        counter-reset: lst-ctn-kix_list_16-7 0
    }

    .lst-kix_list_11-2>li {
        counter-increment: lst-ctn-kix_list_11-2
    }

    .lst-kix_list_19-2>li {
        counter-increment: lst-ctn-kix_list_19-2
    }

    ol.lst-kix_list_8-8 {
        list-style-type: none
    }

    .lst-kix_list_12-3>li:before {
        content: "" counter(lst-ctn-kix_list_12-0, decimal) "." counter(lst-ctn-kix_list_12-1, decimal) "." counter(lst-ctn-kix_list_12-2, decimal) "." counter(lst-ctn-kix_list_12-3, decimal) ". "
    }

    ol.lst-kix_list_8-4 {
        list-style-type: none
    }

    .lst-kix_list_12-1>li:before {
        content: "" counter(lst-ctn-kix_list_12-0, decimal) "." counter(lst-ctn-kix_list_12-1, decimal) ". "
    }

    ol.lst-kix_list_8-5 {
        list-style-type: none
    }

    ol.lst-kix_list_8-6 {
        list-style-type: none
    }

    ol.lst-kix_list_8-7 {
        list-style-type: none
    }

    ol.lst-kix_list_8-0 {
        list-style-type: none
    }

    .lst-kix_list_16-3>li {
        counter-increment: lst-ctn-kix_list_16-3
    }

    ol.lst-kix_list_8-1 {
        list-style-type: none
    }

    .lst-kix_list_23-6>li {
        counter-increment: lst-ctn-kix_list_23-6
    }

    ol.lst-kix_list_8-2 {
        list-style-type: none
    }

    .lst-kix_list_13-3>li {
        counter-increment: lst-ctn-kix_list_13-3
    }

    ol.lst-kix_list_13-6.start {
        counter-reset: lst-ctn-kix_list_13-6 0
    }

    ol.lst-kix_list_8-3 {
        list-style-type: none
    }

    .lst-kix_list_10-4>li {
        counter-increment: lst-ctn-kix_list_10-4
    }

    .lst-kix_list_14-1>li {
        counter-increment: lst-ctn-kix_list_14-1
    }

    .lst-kix_list_21-4>li {
        counter-increment: lst-ctn-kix_list_21-4
    }

    .lst-kix_list_13-3>li:before {
        content: "" counter(lst-ctn-kix_list_13-0, decimal) "." counter(lst-ctn-kix_list_13-1, decimal) "." counter(lst-ctn-kix_list_13-2, decimal) "." counter(lst-ctn-kix_list_13-3, decimal) ". "
    }

    .lst-kix_list_13-5>li:before {
        content: "(" counter(lst-ctn-kix_list_13-4, decimal) ")" counter(lst-ctn-kix_list_13-5, decimal) ". "
    }

    .lst-kix_list_12-5>li:before {
        content: "(" counter(lst-ctn-kix_list_12-4, decimal) ")" counter(lst-ctn-kix_list_12-5, decimal) ". "
    }

    ol.lst-kix_list_13-7.start {
        counter-reset: lst-ctn-kix_list_13-7 0
    }

    .lst-kix_list_18-4>li {
        counter-increment: lst-ctn-kix_list_18-4
    }

    .lst-kix_list_12-7>li:before {
        content: "(" counter(lst-ctn-kix_list_12-4, decimal) ")" counter(lst-ctn-kix_list_12-5, decimal) "." counter(lst-ctn-kix_list_12-6, decimal) "." counter(lst-ctn-kix_list_12-7, decimal) ". "
    }

    ol.lst-kix_list_21-1.start {
        counter-reset: lst-ctn-kix_list_21-1 0
    }

    .lst-kix_list_13-1>li:before {
        content: "" counter(lst-ctn-kix_list_13-0, decimal) "." counter(lst-ctn-kix_list_13-1, decimal) ". "
    }

    .lst-kix_list_22-8>li {
        counter-increment: lst-ctn-kix_list_22-8
    }

    ol.lst-kix_list_11-6 {
        list-style-type: none
    }

    ol.lst-kix_list_11-7 {
        list-style-type: none
    }

    ol.lst-kix_list_11-8 {
        list-style-type: none
    }

    .lst-kix_list_1-1>li {
        counter-increment: lst-ctn-kix_list_1-1
    }

    ol.lst-kix_list_11-2 {
        list-style-type: none
    }

    ol.lst-kix_list_11-3 {
        list-style-type: none
    }

    ol.lst-kix_list_2-6.start {
        counter-reset: lst-ctn-kix_list_2-6 0
    }

    .lst-kix_list_3-0>li:before {
        content: "" counter(lst-ctn-kix_list_3-0, decimal) ") "
    }

    ol.lst-kix_list_11-4 {
        list-style-type: none
    }

    ol.lst-kix_list_11-5 {
        list-style-type: none
    }

    ol.lst-kix_list_20-5.start {
        counter-reset: lst-ctn-kix_list_20-5 0
    }

    ol.lst-kix_list_13-1.start {
        counter-reset: lst-ctn-kix_list_13-1 0
    }

    ol.lst-kix_list_11-0 {
        list-style-type: none
    }

    ol.lst-kix_list_11-1 {
        list-style-type: none
    }

    .lst-kix_list_4-0>li {
        counter-increment: lst-ctn-kix_list_4-0
    }

    .lst-kix_list_3-4>li:before {
        content: "(" counter(lst-ctn-kix_list_3-4, lower-latin) ") "
    }

    .lst-kix_list_3-3>li:before {
        content: "(" counter(lst-ctn-kix_list_3-3, decimal) ") "
    }

    .lst-kix_list_8-0>li:before {
        content: "" counter(lst-ctn-kix_list_8-0, decimal) ". "
    }

    .lst-kix_list_8-7>li:before {
        content: "(" counter(lst-ctn-kix_list_8-4, decimal) ")" counter(lst-ctn-kix_list_8-5, decimal) "." counter(lst-ctn-kix_list_8-6, decimal) "." counter(lst-ctn-kix_list_8-7, decimal) ". "
    }

    .lst-kix_list_3-8>li:before {
        content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". "
    }

    ol.lst-kix_list_10-7.start {
        counter-reset: lst-ctn-kix_list_10-7 0
    }

    .lst-kix_list_8-3>li:before {
        content: "" counter(lst-ctn-kix_list_8-0, decimal) "." counter(lst-ctn-kix_list_8-1, decimal) "." counter(lst-ctn-kix_list_8-2, decimal) "." counter(lst-ctn-kix_list_8-3, decimal) ". "
    }

    ol.lst-kix_list_22-7 {
        list-style-type: none
    }

    ol.lst-kix_list_22-6 {
        list-style-type: none
    }

    ol.lst-kix_list_22-8 {
        list-style-type: none
    }

    .lst-kix_list_3-7>li:before {
        content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". "
    }

    ol.lst-kix_list_22-3 {
        list-style-type: none
    }

    ol.lst-kix_list_22-2 {
        list-style-type: none
    }

    .lst-kix_list_8-4>li:before {
        content: "(" counter(lst-ctn-kix_list_8-4, decimal) ") "
    }

    ol.lst-kix_list_22-5 {
        list-style-type: none
    }

    ol.lst-kix_list_22-4 {
        list-style-type: none
    }

    .lst-kix_list_19-1>li {
        counter-increment: lst-ctn-kix_list_19-1
    }

    ol.lst-kix_list_22-1 {
        list-style-type: none
    }

    ol.lst-kix_list_22-0 {
        list-style-type: none
    }

    ol.lst-kix_list_8-5.start {
        counter-reset: lst-ctn-kix_list_8-5 0
    }

    .lst-kix_list_17-1>li {
        counter-increment: lst-ctn-kix_list_17-1
    }

    .lst-kix_list_11-1>li:before {
        content: "" counter(lst-ctn-kix_list_11-1, lower-latin) ") "
    }

    .lst-kix_list_11-0>li:before {
        content: "" counter(lst-ctn-kix_list_11-0, decimal) ") "
    }

    ol.lst-kix_list_9-3.start {
        counter-reset: lst-ctn-kix_list_9-3 0
    }

    .lst-kix_list_8-8>li:before {
        content: "(" counter(lst-ctn-kix_list_8-4, decimal) ")" counter(lst-ctn-kix_list_8-5, decimal) "." counter(lst-ctn-kix_list_8-6, decimal) "." counter(lst-ctn-kix_list_8-7, decimal) "." counter(lst-ctn-kix_list_8-8, decimal) ". "
    }

    ol.lst-kix_list_2-2 {
        list-style-type: none
    }

    .lst-kix_list_16-8>li:before {
        content: "(" counter(lst-ctn-kix_list_16-4, decimal) ")" counter(lst-ctn-kix_list_16-5, decimal) "." counter(lst-ctn-kix_list_16-6, decimal) "." counter(lst-ctn-kix_list_16-7, decimal) "." counter(lst-ctn-kix_list_16-8, decimal) ". "
    }

    ol.lst-kix_list_2-3 {
        list-style-type: none
    }

    ol.lst-kix_list_2-4 {
        list-style-type: none
    }

    .lst-kix_list_16-7>li:before {
        content: "(" counter(lst-ctn-kix_list_16-4, decimal) ")" counter(lst-ctn-kix_list_16-5, decimal) "." counter(lst-ctn-kix_list_16-6, decimal) "." counter(lst-ctn-kix_list_16-7, decimal) ". "
    }

    ol.lst-kix_list_2-5 {
        list-style-type: none
    }

    .lst-kix_list_17-8>li {
        counter-increment: lst-ctn-kix_list_17-8
    }

    ol.lst-kix_list_2-0 {
        list-style-type: none
    }

    ol.lst-kix_list_2-1 {
        list-style-type: none
    }

    .lst-kix_list_4-8>li:before {
        content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". "
    }

    .lst-kix_list_21-5>li {
        counter-increment: lst-ctn-kix_list_21-5
    }

    .lst-kix_list_4-7>li:before {
        content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". "
    }

    .lst-kix_list_14-2>li {
        counter-increment: lst-ctn-kix_list_14-2
    }

    ol.lst-kix_list_20-0.start {
        counter-reset: lst-ctn-kix_list_20-0 0
    }

    .lst-kix_list_17-0>li:before {
        content: "" counter(lst-ctn-kix_list_17-0, decimal) ". "
    }

    .lst-kix_list_16-0>li:before {
        content: "" counter(lst-ctn-kix_list_16-0, decimal) ". "
    }

    ol.lst-kix_list_4-8.start {
        counter-reset: lst-ctn-kix_list_4-8 0
    }

    .lst-kix_list_8-4>li {
        counter-increment: lst-ctn-kix_list_8-4
    }

    .lst-kix_list_16-4>li:before {
        content: "" counter(lst-ctn-kix_list_16-0, decimal) "." counter(lst-ctn-kix_list_16-1, decimal) "." counter(lst-ctn-kix_list_16-2, decimal) "." counter(lst-ctn-kix_list_16-3, decimal) "." counter(lst-ctn-kix_list_16-4, decimal) ". "
    }

    ol.lst-kix_list_3-3.start {
        counter-reset: lst-ctn-kix_list_3-3 0
    }

    .lst-kix_list_16-3>li:before {
        content: "" counter(lst-ctn-kix_list_16-0, decimal) "." counter(lst-ctn-kix_list_16-1, decimal) "." counter(lst-ctn-kix_list_16-2, decimal) "." counter(lst-ctn-kix_list_16-3, decimal) ". "
    }

    ol.lst-kix_list_2-6 {
        list-style-type: none
    }

    ol.lst-kix_list_2-7 {
        list-style-type: none
    }

    ol.lst-kix_list_2-8 {
        list-style-type: none
    }

    .lst-kix_list_11-3>li {
        counter-increment: lst-ctn-kix_list_11-3
    }

    ol.lst-kix_list_18-1.start {
        counter-reset: lst-ctn-kix_list_18-1 0
    }

    ol.lst-kix_list_8-6.start {
        counter-reset: lst-ctn-kix_list_8-6 0
    }

    .lst-kix_list_17-7>li:before {
        content: "(" counter(lst-ctn-kix_list_17-4, decimal) ")" counter(lst-ctn-kix_list_17-5, decimal) "." counter(lst-ctn-kix_list_17-6, decimal) "." counter(lst-ctn-kix_list_17-7, decimal) ". "
    }

    .lst-kix_list_17-8>li:before {
        content: "(" counter(lst-ctn-kix_list_17-4, decimal) ")" counter(lst-ctn-kix_list_17-5, decimal) "." counter(lst-ctn-kix_list_17-6, decimal) "." counter(lst-ctn-kix_list_17-7, decimal) "." counter(lst-ctn-kix_list_17-8, decimal) ". "
    }

    .lst-kix_list_17-3>li:before {
        content: "" counter(lst-ctn-kix_list_17-0, decimal) "." counter(lst-ctn-kix_list_17-1, decimal) "." counter(lst-ctn-kix_list_17-2, decimal) "." counter(lst-ctn-kix_list_17-3, decimal) ". "
    }

    .lst-kix_list_17-4>li:before {
        content: "" counter(lst-ctn-kix_list_17-0, decimal) "." counter(lst-ctn-kix_list_17-1, decimal) "." counter(lst-ctn-kix_list_17-2, decimal) "." counter(lst-ctn-kix_list_17-3, decimal) "." counter(lst-ctn-kix_list_17-4, decimal) ". "
    }

    ol.lst-kix_list_8-0.start {
        counter-reset: lst-ctn-kix_list_8-0 0
    }

    .lst-kix_list_7-0>li:before {
        content: "" counter(lst-ctn-kix_list_7-0, decimal) ". "
    }

    ol.lst-kix_list_19-6.start {
        counter-reset: lst-ctn-kix_list_19-6 0
    }

    ol.lst-kix_list_9-7.start {
        counter-reset: lst-ctn-kix_list_9-7 0
    }

    ol.lst-kix_list_13-8 {
        list-style-type: none
    }

    .lst-kix_list_2-4>li:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) "." counter(lst-ctn-kix_list_2-3, decimal) "." counter(lst-ctn-kix_list_2-4, decimal) ". "
    }

    .lst-kix_list_2-8>li:before {
        content: " "
    }

    ol.lst-kix_list_13-4 {
        list-style-type: none
    }

    ol.lst-kix_list_13-5 {
        list-style-type: none
    }

    ol.lst-kix_list_13-6 {
        list-style-type: none
    }

    ol.lst-kix_list_13-7 {
        list-style-type: none
    }

    ol.lst-kix_list_13-0 {
        list-style-type: none
    }

    ol.lst-kix_list_13-1 {
        list-style-type: none
    }

    ol.lst-kix_list_13-2 {
        list-style-type: none
    }

    .lst-kix_list_7-3>li:before {
        content: "" counter(lst-ctn-kix_list_7-0, decimal) "." counter(lst-ctn-kix_list_7-1, decimal) "." counter(lst-ctn-kix_list_7-2, decimal) "." counter(lst-ctn-kix_list_7-3, decimal) ". "
    }

    ol.lst-kix_list_13-3 {
        list-style-type: none
    }

    .lst-kix_list_10-0>li:before {
        content: "" counter(lst-ctn-kix_list_10-0, decimal) ") "
    }

    .lst-kix_list_9-7>li {
        counter-increment: lst-ctn-kix_list_9-7
    }

    ol.lst-kix_list_21-7.start {
        counter-reset: lst-ctn-kix_list_21-7 0
    }

    .lst-kix_list_13-8>li:before {
        content: "(" counter(lst-ctn-kix_list_13-4, decimal) ")" counter(lst-ctn-kix_list_13-5, decimal) "." counter(lst-ctn-kix_list_13-6, decimal) "." counter(lst-ctn-kix_list_13-7, decimal) "." counter(lst-ctn-kix_list_13-8, decimal) ". "
    }

    .lst-kix_list_18-3>li:before {
        content: "" counter(lst-ctn-kix_list_18-0, decimal) "." counter(lst-ctn-kix_list_18-1, decimal) "." counter(lst-ctn-kix_list_18-2, decimal) "." counter(lst-ctn-kix_list_18-3, decimal) ". "
    }

    .lst-kix_list_18-7>li:before {
        content: "(" counter(lst-ctn-kix_list_18-4, decimal) ")" counter(lst-ctn-kix_list_18-5, decimal) "." counter(lst-ctn-kix_list_18-6, decimal) "." counter(lst-ctn-kix_list_18-7, decimal) ". "
    }

    .lst-kix_list_18-6>li {
        counter-increment: lst-ctn-kix_list_18-6
    }

    ol.lst-kix_list_3-8.start {
        counter-reset: lst-ctn-kix_list_3-8 0
    }

    .lst-kix_list_7-7>li:before {
        content: "(" counter(lst-ctn-kix_list_7-4, decimal) ")" counter(lst-ctn-kix_list_7-5, decimal) "." counter(lst-ctn-kix_list_7-6, decimal) "." counter(lst-ctn-kix_list_7-7, decimal) ". "
    }

    ol.lst-kix_list_8-1.start {
        counter-reset: lst-ctn-kix_list_8-1 0
    }

    .lst-kix_list_15-4>li:before {
        content: "(" counter(lst-ctn-kix_list_15-4, lower-latin) ") "
    }

    ol.lst-kix_list_19-1.start {
        counter-reset: lst-ctn-kix_list_19-1 0
    }

    .lst-kix_list_10-4>li:before {
        content: "(" counter(lst-ctn-kix_list_10-4, lower-latin) ") "
    }

    .lst-kix_list_10-8>li:before {
        content: "" counter(lst-ctn-kix_list_10-8, lower-roman) ". "
    }

    ol.lst-kix_list_20-4.start {
        counter-reset: lst-ctn-kix_list_20-4 0
    }

    .lst-kix_list_4-0>li:before {
        content: "" counter(lst-ctn-kix_list_4-0, decimal) ") "
    }

    .lst-kix_list_15-0>li:before {
        content: "" counter(lst-ctn-kix_list_15-0, decimal) ") "
    }

    .lst-kix_list_15-8>li:before {
        content: "" counter(lst-ctn-kix_list_15-8, lower-roman) ". "
    }

    ol.lst-kix_list_14-3.start {
        counter-reset: lst-ctn-kix_list_14-3 0
    }

    .lst-kix_list_15-7>li {
        counter-increment: lst-ctn-kix_list_15-7
    }

    .lst-kix_list_4-4>li:before {
        content: "(" counter(lst-ctn-kix_list_4-4, lower-latin) ") "
    }

    .lst-kix_list_9-3>li:before {
        content: "(" counter(lst-ctn-kix_list_9-3, decimal) ") "
    }

    ol.lst-kix_list_7-0.start {
        counter-reset: lst-ctn-kix_list_7-0 0
    }

    .lst-kix_list_12-8>li {
        counter-increment: lst-ctn-kix_list_12-8
    }

    ol.lst-kix_list_13-2.start {
        counter-reset: lst-ctn-kix_list_13-2 0
    }

    ol.lst-kix_list_4-0 {
        list-style-type: none
    }

    ol.lst-kix_list_4-1 {
        list-style-type: none
    }

    ol.lst-kix_list_4-2 {
        list-style-type: none
    }

    ol.lst-kix_list_4-3 {
        list-style-type: none
    }

    ol.lst-kix_list_14-4.start {
        counter-reset: lst-ctn-kix_list_14-4 0
    }

    .lst-kix_list_9-7>li:before {
        content: "" counter(lst-ctn-kix_list_9-7, lower-latin) ". "
    }

    .lst-kix_list_2-4>li {
        counter-increment: lst-ctn-kix_list_2-4
    }

    ol.lst-kix_list_3-6.start {
        counter-reset: lst-ctn-kix_list_3-6 0
    }

    .lst-kix_list_11-4>li:before {
        content: "(" counter(lst-ctn-kix_list_11-4, lower-latin) ") "
    }

    .lst-kix_list_12-4>li:before {
        content: "(" counter(lst-ctn-kix_list_12-4, decimal) ") "
    }

    .lst-kix_list_5-3>li {
        counter-increment: lst-ctn-kix_list_5-3
    }

    ol.lst-kix_list_4-8 {
        list-style-type: none
    }

    .lst-kix_list_7-4>li {
        counter-increment: lst-ctn-kix_list_7-4
    }

    .lst-kix_list_1-0>li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) ". "
    }

    ol.lst-kix_list_19-2.start {
        counter-reset: lst-ctn-kix_list_19-2 0
    }

    ol.lst-kix_list_4-4 {
        list-style-type: none
    }

    .lst-kix_list_11-8>li:before {
        content: "" counter(lst-ctn-kix_list_11-8, lower-roman) ". "
    }

    ol.lst-kix_list_4-5 {
        list-style-type: none
    }

    ol.lst-kix_list_2-0.start {
        counter-reset: lst-ctn-kix_list_2-0 0
    }

    ol.lst-kix_list_4-6 {
        list-style-type: none
    }

    .lst-kix_list_12-0>li:before {
        content: "" counter(lst-ctn-kix_list_12-0, decimal) ". "
    }

    ol.lst-kix_list_4-7 {
        list-style-type: none
    }

    .lst-kix_list_1-4>li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) "." counter(lst-ctn-kix_list_1-3, decimal) "." counter(lst-ctn-kix_list_1-4, decimal) ". "
    }

    .lst-kix_list_13-0>li:before {
        content: "" counter(lst-ctn-kix_list_13-0, decimal) ". "
    }

    .lst-kix_list_1-6>li {
        counter-increment: lst-ctn-kix_list_1-6
    }

    .lst-kix_list_13-4>li:before {
        content: "(" counter(lst-ctn-kix_list_13-4, decimal) ") "
    }

    ol.lst-kix_list_19-3.start {
        counter-reset: lst-ctn-kix_list_19-3 0
    }

    .lst-kix_list_2-0>li:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) ". "
    }

    ol.lst-kix_list_2-1.start {
        counter-reset: lst-ctn-kix_list_2-1 0
    }

    .lst-kix_list_4-5>li {
        counter-increment: lst-ctn-kix_list_4-5
    }

    ol.lst-kix_list_9-8.start {
        counter-reset: lst-ctn-kix_list_9-8 0
    }

    .lst-kix_list_1-8>li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) "." counter(lst-ctn-kix_list_1-3, decimal) "." counter(lst-ctn-kix_list_1-4, decimal) "." counter(lst-ctn-kix_list_1-5, decimal) "." counter(lst-ctn-kix_list_1-6, decimal) "." counter(lst-ctn-kix_list_1-7, decimal) "." counter(lst-ctn-kix_list_1-8, decimal) ". "
    }

    .lst-kix_list_12-8>li:before {
        content: "(" counter(lst-ctn-kix_list_12-4, decimal) ")" counter(lst-ctn-kix_list_12-5, decimal) "." counter(lst-ctn-kix_list_12-6, decimal) "." counter(lst-ctn-kix_list_12-7, decimal) "." counter(lst-ctn-kix_list_12-8, decimal) ". "
    }

    .lst-kix_list_8-2>li {
        counter-increment: lst-ctn-kix_list_8-2
    }

    .lst-kix_list_19-0>li:before {
        content: "" counter(lst-ctn-kix_list_19-0, decimal) ". "
    }

    ol.lst-kix_list_17-7.start {
        counter-reset: lst-ctn-kix_list_17-7 0
    }

    ol.lst-kix_list_12-6.start {
        counter-reset: lst-ctn-kix_list_12-6 0
    }

    .lst-kix_list_23-8>li {
        counter-increment: lst-ctn-kix_list_23-8
    }

    .lst-kix_list_8-1>li {
        counter-increment: lst-ctn-kix_list_8-1
    }

    ol.lst-kix_list_8-2.start {
        counter-reset: lst-ctn-kix_list_8-2 0
    }

    .lst-kix_list_19-2>li:before {
        content: "" counter(lst-ctn-kix_list_19-0, decimal) "." counter(lst-ctn-kix_list_19-1, decimal) "." counter(lst-ctn-kix_list_19-2, decimal) ". "
    }

    ol.lst-kix_list_3-1.start {
        counter-reset: lst-ctn-kix_list_3-1 0
    }

    ol.lst-kix_list_21-0.start {
        counter-reset: lst-ctn-kix_list_21-0 0
    }

    .lst-kix_list_7-0>li {
        counter-increment: lst-ctn-kix_list_7-0
    }

    .lst-kix_list_19-0>li {
        counter-increment: lst-ctn-kix_list_19-0
    }

    .lst-kix_list_2-3>li {
        counter-increment: lst-ctn-kix_list_2-3
    }

    ol.lst-kix_list_19-8.start {
        counter-reset: lst-ctn-kix_list_19-8 0
    }

    .lst-kix_list_1-2>li {
        counter-increment: lst-ctn-kix_list_1-2
    }

    .lst-kix_list_19-8>li:before {
        content: "(" counter(lst-ctn-kix_list_19-4, decimal) ")" counter(lst-ctn-kix_list_19-5, decimal) "." counter(lst-ctn-kix_list_19-6, decimal) "." counter(lst-ctn-kix_list_19-7, decimal) "." counter(lst-ctn-kix_list_19-8, decimal) ". "
    }

    ol.lst-kix_list_14-7.start {
        counter-reset: lst-ctn-kix_list_14-7 0
    }

    ol.lst-kix_list_20-8.start {
        counter-reset: lst-ctn-kix_list_20-8 0
    }

    .lst-kix_list_19-5>li:before {
        content: "(" counter(lst-ctn-kix_list_19-4, decimal) ")" counter(lst-ctn-kix_list_19-5, decimal) ". "
    }

    .lst-kix_list_19-7>li:before {
        content: "(" counter(lst-ctn-kix_list_19-4, decimal) ")" counter(lst-ctn-kix_list_19-5, decimal) "." counter(lst-ctn-kix_list_19-6, decimal) "." counter(lst-ctn-kix_list_19-7, decimal) ". "
    }

    .lst-kix_list_9-2>li {
        counter-increment: lst-ctn-kix_list_9-2
    }

    ol.lst-kix_list_23-8.start {
        counter-reset: lst-ctn-kix_list_23-8 0
    }

    ol.lst-kix_list_17-2.start {
        counter-reset: lst-ctn-kix_list_17-2 0
    }

    .lst-kix_list_13-2>li {
        counter-increment: lst-ctn-kix_list_13-2
    }

    ol.lst-kix_list_21-5.start {
        counter-reset: lst-ctn-kix_list_21-5 0
    }

    .lst-kix_list_19-7>li {
        counter-increment: lst-ctn-kix_list_19-7
    }

    .lst-kix_list_14-3>li {
        counter-increment: lst-ctn-kix_list_14-3
    }

    ol.lst-kix_list_15-6.start {
        counter-reset: lst-ctn-kix_list_15-6 0
    }

    .lst-kix_list_12-1>li {
        counter-increment: lst-ctn-kix_list_12-1
    }

    .lst-kix_list_18-1>li:before {
        content: "" counter(lst-ctn-kix_list_18-0, decimal) "." counter(lst-ctn-kix_list_18-1, decimal) ". "
    }

    .lst-kix_list_23-4>li {
        counter-increment: lst-ctn-kix_list_23-4
    }

    ol.lst-kix_list_23-1.start {
        counter-reset: lst-ctn-kix_list_23-1 0
    }

    ol.lst-kix_list_2-4.start {
        counter-reset: lst-ctn-kix_list_2-4 0
    }

    .lst-kix_list_23-1>li {
        counter-increment: lst-ctn-kix_list_23-1
    }

    ol.lst-kix_list_1-3 {
        list-style-type: none
    }

    ol.lst-kix_list_1-4 {
        list-style-type: none
    }

    .lst-kix_list_2-7>li:before {
        content: " "
    }

    .lst-kix_list_2-7>li {
        counter-increment: lst-ctn-kix_list_2-7
    }

    ol.lst-kix_list_1-5 {
        list-style-type: none
    }

    ol.lst-kix_list_1-6 {
        list-style-type: none
    }

    ol.lst-kix_list_1-0 {
        list-style-type: none
    }

    .lst-kix_list_2-5>li:before {
        content: " "
    }

    ol.lst-kix_list_1-1 {
        list-style-type: none
    }

    ol.lst-kix_list_1-2 {
        list-style-type: none
    }

    ol.lst-kix_list_17-0.start {
        counter-reset: lst-ctn-kix_list_17-0 0
    }

    ol.lst-kix_list_10-3.start {
        counter-reset: lst-ctn-kix_list_10-3 0
    }

    .lst-kix_list_22-3>li {
        counter-increment: lst-ctn-kix_list_22-3
    }

    .lst-kix_list_18-6>li:before {
        content: "(" counter(lst-ctn-kix_list_18-4, decimal) ")" counter(lst-ctn-kix_list_18-5, decimal) "." counter(lst-ctn-kix_list_18-6, decimal) ". "
    }

    .lst-kix_list_14-6>li {
        counter-increment: lst-ctn-kix_list_14-6
    }

    .lst-kix_list_10-1>li:before {
        content: "" counter(lst-ctn-kix_list_10-1, lower-latin) ") "
    }

    .lst-kix_list_18-4>li:before {
        content: "" counter(lst-ctn-kix_list_18-0, decimal) "." counter(lst-ctn-kix_list_18-1, decimal) "." counter(lst-ctn-kix_list_18-2, decimal) "." counter(lst-ctn-kix_list_18-3, decimal) "." counter(lst-ctn-kix_list_18-4, decimal) ". "
    }

    .lst-kix_list_7-7>li {
        counter-increment: lst-ctn-kix_list_7-7
    }

    ol.lst-kix_list_15-1.start {
        counter-reset: lst-ctn-kix_list_15-1 0
    }

    ol.lst-kix_list_15-4.start {
        counter-reset: lst-ctn-kix_list_15-4 0
    }

    ol.lst-kix_list_1-7 {
        list-style-type: none
    }

    ol.lst-kix_list_1-8 {
        list-style-type: none
    }

    .lst-kix_list_10-3>li:before {
        content: "(" counter(lst-ctn-kix_list_10-3, decimal) ") "
    }

    .lst-kix_list_15-4>li {
        counter-increment: lst-ctn-kix_list_15-4
    }

    .lst-kix_list_2-6>li {
        counter-increment: lst-ctn-kix_list_2-6
    }

    ol.lst-kix_list_7-3.start {
        counter-reset: lst-ctn-kix_list_7-3 0
    }

    ol.lst-kix_list_5-7.start {
        counter-reset: lst-ctn-kix_list_5-7 0
    }

    .lst-kix_list_20-8>li:before {
        content: "(" counter(lst-ctn-kix_list_20-4, decimal) ")" counter(lst-ctn-kix_list_20-5, decimal) "." counter(lst-ctn-kix_list_20-6, decimal) "." counter(lst-ctn-kix_list_20-7, decimal) "." counter(lst-ctn-kix_list_20-8, decimal) ". "
    }

    .lst-kix_list_3-4>li {
        counter-increment: lst-ctn-kix_list_3-4
    }

    .lst-kix_list_20-0>li:before {
        content: "" counter(lst-ctn-kix_list_20-0, decimal) ". "
    }

    ol.lst-kix_list_10-7 {
        list-style-type: none
    }

    .lst-kix_list_9-6>li:before {
        content: "" counter(lst-ctn-kix_list_9-6, decimal) ". "
    }

    ol.lst-kix_list_10-8 {
        list-style-type: none
    }

    ol.lst-kix_list_10-3 {
        list-style-type: none
    }

    .lst-kix_list_9-4>li:before {
        content: "(" counter(lst-ctn-kix_list_9-4, lower-latin) ") "
    }

    ol.lst-kix_list_10-4 {
        list-style-type: none
    }

    ol.lst-kix_list_10-5 {
        list-style-type: none
    }

    ol.lst-kix_list_10-6 {
        list-style-type: none
    }

    .lst-kix_list_20-6>li:before {
        content: "(" counter(lst-ctn-kix_list_20-4, decimal) ")" counter(lst-ctn-kix_list_20-5, decimal) "." counter(lst-ctn-kix_list_20-6, decimal) ". "
    }

    .lst-kix_list_23-0>li {
        counter-increment: lst-ctn-kix_list_23-0
    }

    ol.lst-kix_list_10-0 {
        list-style-type: none
    }

    ol.lst-kix_list_10-1 {
        list-style-type: none
    }

    ol.lst-kix_list_10-2 {
        list-style-type: none
    }

    ol.lst-kix_list_12-1.start {
        counter-reset: lst-ctn-kix_list_12-1 0
    }

    .lst-kix_list_11-5>li:before {
        content: "(" counter(lst-ctn-kix_list_11-5, lower-roman) ") "
    }

    ol.lst-kix_list_21-2.start {
        counter-reset: lst-ctn-kix_list_21-2 0
    }

    .lst-kix_list_20-6>li {
        counter-increment: lst-ctn-kix_list_20-6
    }

    .lst-kix_list_1-1>li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) ". "
    }

    .lst-kix_list_11-7>li:before {
        content: "" counter(lst-ctn-kix_list_11-7, lower-latin) ". "
    }

    .lst-kix_list_8-5>li {
        counter-increment: lst-ctn-kix_list_8-5
    }

    .lst-kix_list_1-3>li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) "." counter(lst-ctn-kix_list_1-3, decimal) ". "
    }

    ol.lst-kix_list_10-5.start {
        counter-reset: lst-ctn-kix_list_10-5 0
    }

    ol.lst-kix_list_21-8 {
        list-style-type: none
    }

    ol.lst-kix_list_21-7 {
        list-style-type: none
    }

    ol.lst-kix_list_2-7.start {
        counter-reset: lst-ctn-kix_list_2-7 0
    }

    ol.lst-kix_list_21-4 {
        list-style-type: none
    }

    ol.lst-kix_list_21-3 {
        list-style-type: none
    }

    ol.lst-kix_list_21-6 {
        list-style-type: none
    }

    .lst-kix_list_14-7>li {
        counter-increment: lst-ctn-kix_list_14-7
    }

    ol.lst-kix_list_21-5 {
        list-style-type: none
    }

    ol.lst-kix_list_21-0 {
        list-style-type: none
    }

    ol.lst-kix_list_7-5.start {
        counter-reset: lst-ctn-kix_list_7-5 0
    }

    ol.lst-kix_list_21-2 {
        list-style-type: none
    }

    ol.lst-kix_list_21-1 {
        list-style-type: none
    }

    ol.lst-kix_list_19-6 {
        list-style-type: none
    }

    ol.lst-kix_list_19-7 {
        list-style-type: none
    }

    .lst-kix_list_3-1>li {
        counter-increment: lst-ctn-kix_list_3-1
    }

    ol.lst-kix_list_19-8 {
        list-style-type: none
    }

    ol.lst-kix_list_19-2 {
        list-style-type: none
    }

    ol.lst-kix_list_19-3 {
        list-style-type: none
    }

    ol.lst-kix_list_19-4 {
        list-style-type: none
    }

    ol.lst-kix_list_19-5 {
        list-style-type: none
    }

    ol.lst-kix_list_19-0 {
        list-style-type: none
    }

    ol.lst-kix_list_19-1 {
        list-style-type: none
    }

    ol.lst-kix_list_7-7.start {
        counter-reset: lst-ctn-kix_list_7-7 0
    }

    .lst-kix_list_3-1>li:before {
        content: "" counter(lst-ctn-kix_list_3-1, lower-latin) ") "
    }

    .lst-kix_list_14-0>li {
        counter-increment: lst-ctn-kix_list_14-0
    }

    ol.lst-kix_list_17-4.start {
        counter-reset: lst-ctn-kix_list_17-4 0
    }

    .lst-kix_list_8-2>li:before {
        content: "" counter(lst-ctn-kix_list_8-0, decimal) "." counter(lst-ctn-kix_list_8-1, decimal) "." counter(lst-ctn-kix_list_8-2, decimal) ". "
    }

    .lst-kix_list_12-0>li {
        counter-increment: lst-ctn-kix_list_12-0
    }

    ol.lst-kix_list_12-3.start {
        counter-reset: lst-ctn-kix_list_12-3 0
    }

    .lst-kix_list_21-2>li:before {
        content: "" counter(lst-ctn-kix_list_21-2, lower-roman) ") "
    }

    .lst-kix_list_8-5>li:before {
        content: "(" counter(lst-ctn-kix_list_8-4, decimal) ")" counter(lst-ctn-kix_list_8-5, decimal) ". "
    }

    .lst-kix_list_2-0>li {
        counter-increment: lst-ctn-kix_list_2-0
    }

    .lst-kix_list_15-1>li {
        counter-increment: lst-ctn-kix_list_15-1
    }

    .lst-kix_list_3-6>li:before {
        content: "" counter(lst-ctn-kix_list_3-6, decimal) ". "
    }

    .lst-kix_list_21-7>li:before {
        content: "" counter(lst-ctn-kix_list_21-7, lower-latin) ". "
    }

    ol.lst-kix_list_5-0.start {
        counter-reset: lst-ctn-kix_list_5-0 0
    }

    .lst-kix_list_11-2>li:before {
        content: "" counter(lst-ctn-kix_list_11-2, lower-roman) ") "
    }

    ol.lst-kix_list_12-4.start {
        counter-reset: lst-ctn-kix_list_12-4 0
    }

    .lst-kix_list_16-6>li:before {
        content: "(" counter(lst-ctn-kix_list_16-4, decimal) ")" counter(lst-ctn-kix_list_16-5, decimal) "." counter(lst-ctn-kix_list_16-6, decimal) ". "
    }

    ol.lst-kix_list_10-1.start {
        counter-reset: lst-ctn-kix_list_10-1 0
    }

    .lst-kix_list_18-2>li {
        counter-increment: lst-ctn-kix_list_18-2
    }

    ol.lst-kix_list_5-6.start {
        counter-reset: lst-ctn-kix_list_5-6 0
    }

    .lst-kix_list_22-6>li {
        counter-increment: lst-ctn-kix_list_22-6
    }

    .lst-kix_list_16-1>li:before {
        content: "" counter(lst-ctn-kix_list_16-0, decimal) "." counter(lst-ctn-kix_list_16-1, decimal) ". "
    }

    .lst-kix_list_7-3>li {
        counter-increment: lst-ctn-kix_list_7-3
    }

    .lst-kix_list_19-3>li {
        counter-increment: lst-ctn-kix_list_19-3
    }

    ol.lst-kix_list_7-8.start {
        counter-reset: lst-ctn-kix_list_7-8 0
    }

    .lst-kix_list_12-4>li {
        counter-increment: lst-ctn-kix_list_12-4
    }

    .lst-kix_list_23-7>li {
        counter-increment: lst-ctn-kix_list_23-7
    }

    ol.lst-kix_list_10-2.start {
        counter-reset: lst-ctn-kix_list_10-2 0
    }

    .lst-kix_list_12-7>li {
        counter-increment: lst-ctn-kix_list_12-7
    }

    .lst-kix_list_17-2>li:before {
        content: "" counter(lst-ctn-kix_list_17-0, decimal) "." counter(lst-ctn-kix_list_17-1, decimal) "." counter(lst-ctn-kix_list_17-2, decimal) ". "
    }

    ol.lst-kix_list_5-5.start {
        counter-reset: lst-ctn-kix_list_5-5 0
    }

    ol.lst-kix_list_17-3.start {
        counter-reset: lst-ctn-kix_list_17-3 0
    }

    .lst-kix_list_17-5>li:before {
        content: "(" counter(lst-ctn-kix_list_17-4, decimal) ")" counter(lst-ctn-kix_list_17-5, decimal) ". "
    }

    .lst-kix_list_6-2>li {
        counter-increment: lst-ctn-kix_list_6-2
    }

    .lst-kix_list_22-3>li:before {
        content: "(" counter(lst-ctn-kix_list_22-3, decimal) ") "
    }

    .lst-kix_list_7-1>li:before {
        content: "" counter(lst-ctn-kix_list_7-0, decimal) "." counter(lst-ctn-kix_list_7-1, decimal) ". "
    }

    .lst-kix_list_13-5>li {
        counter-increment: lst-ctn-kix_list_13-5
    }

    .lst-kix_list_9-6>li {
        counter-increment: lst-ctn-kix_list_9-6
    }

    ol.lst-kix_list_23-3.start {
        counter-reset: lst-ctn-kix_list_23-3 0
    }

    ol.lst-kix_list_5-4.start {
        counter-reset: lst-ctn-kix_list_5-4 0
    }

    ol.lst-kix_list_5-1.start {
        counter-reset: lst-ctn-kix_list_5-1 0
    }

    .lst-kix_list_20-3>li {
        counter-increment: lst-ctn-kix_list_20-3
    }

    .lst-kix_list_16-6>li {
        counter-increment: lst-ctn-kix_list_16-6
    }

    .lst-kix_list_11-6>li {
        counter-increment: lst-ctn-kix_list_11-6
    }

    ol.lst-kix_list_10-0.start {
        counter-reset: lst-ctn-kix_list_10-0 0
    }

    ol.lst-kix_list_17-8.start {
        counter-reset: lst-ctn-kix_list_17-8 0
    }

    .lst-kix_list_3-8>li {
        counter-increment: lst-ctn-kix_list_3-8
    }

    .lst-kix_list_4-6>li {
        counter-increment: lst-ctn-kix_list_4-6
    }

    .lst-kix_list_1-5>li {
        counter-increment: lst-ctn-kix_list_1-5
    }

    ol.lst-kix_list_17-5.start {
        counter-reset: lst-ctn-kix_list_17-5 0
    }

    .lst-kix_list_4-2>li:before {
        content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ") "
    }

    ol.lst-kix_list_23-6.start {
        counter-reset: lst-ctn-kix_list_23-6 0
    }

    .lst-kix_list_17-4>li {
        counter-increment: lst-ctn-kix_list_17-4
    }

    .lst-kix_list_15-2>li:before {
        content: "" counter(lst-ctn-kix_list_15-2, lower-roman) ") "
    }

    .lst-kix_list_10-8>li {
        counter-increment: lst-ctn-kix_list_10-8
    }

    .lst-kix_list_10-6>li:before {
        content: "" counter(lst-ctn-kix_list_10-6, decimal) ". "
    }

    .lst-kix_list_9-1>li:before {
        content: "" counter(lst-ctn-kix_list_9-1, lower-latin) ") "
    }

    ol.lst-kix_list_12-7.start {
        counter-reset: lst-ctn-kix_list_12-7 0
    }

    .lst-kix_list_15-8>li {
        counter-increment: lst-ctn-kix_list_15-8
    }

    ol.lst-kix_list_12-8.start {
        counter-reset: lst-ctn-kix_list_12-8 0
    }

    .lst-kix_list_20-3>li:before {
        content: "" counter(lst-ctn-kix_list_20-0, decimal) "." counter(lst-ctn-kix_list_20-1, decimal) "." counter(lst-ctn-kix_list_20-2, decimal) "." counter(lst-ctn-kix_list_20-3, decimal) ". "
    }

    ol.lst-kix_list_23-5.start {
        counter-reset: lst-ctn-kix_list_23-5 0
    }

    .lst-kix_list_21-1>li {
        counter-increment: lst-ctn-kix_list_21-1
    }

    .lst-kix_list_10-1>li {
        counter-increment: lst-ctn-kix_list_10-1
    }

    .lst-kix_list_8-8>li {
        counter-increment: lst-ctn-kix_list_8-8
    }

    ol.lst-kix_list_17-6.start {
        counter-reset: lst-ctn-kix_list_17-6 0
    }

    .lst-kix_list_1-6>li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) "." counter(lst-ctn-kix_list_1-3, decimal) "." counter(lst-ctn-kix_list_1-4, decimal) "." counter(lst-ctn-kix_list_1-5, decimal) "." counter(lst-ctn-kix_list_1-6, decimal) ". "
    }

    .lst-kix_list_12-6>li:before {
        content: "(" counter(lst-ctn-kix_list_12-4, decimal) ")" counter(lst-ctn-kix_list_12-5, decimal) "." counter(lst-ctn-kix_list_12-6, decimal) ". "
    }

    ol.lst-kix_list_23-4.start {
        counter-reset: lst-ctn-kix_list_23-4 0
    }

    .lst-kix_list_2-2>li:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) ". "
    }

    .lst-kix_list_13-2>li:before {
        content: "" counter(lst-ctn-kix_list_13-0, decimal) "." counter(lst-ctn-kix_list_13-1, decimal) "." counter(lst-ctn-kix_list_13-2, decimal) ". "
    }

    ol.lst-kix_list_5-2.start {
        counter-reset: lst-ctn-kix_list_5-2 0
    }

    ol {
        margin: 0;
        padding: 0
    }

    table td,
    table th {
        padding: 0
    }

    .c11 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 12pt
    }

    .c1 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 12pt
    }

    .c7 {
        margin-left: 18pt;
        padding-top: 0pt;
        padding-left: 10.4pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        text-align: justify
    }

    .c19 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: center
    }

    .c21 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: right
    }

    .c2 {
        color: #000000;
        text-decoration: none;
        vertical-align: baseline;
        font-family: "Roboto";
        font-style: normal
    }

    .c8 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: justify
    }

    .c10 {
        padding-top: 0pt;
        padding-bottom: 18pt;
        line-height: 1.0;
        text-indent: -35.5pt;
        text-align: center
    }

    .c18 {
        padding-top: 0pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        text-align: justify
    }

    .c9 {
        text-decoration-skip-ink: none;
        -webkit-text-decoration-skip: none;
        text-decoration: underline
    }

    .c14 {
        background-color: #ffffff;
        max-width: 433.1pt;
        padding: 72pt 81.1pt 72pt 81.1pt
    }

    .c6 {
        padding: 0;
        margin: 0
    }

    .c15 {
        font-weight: 400;
        font-size: 12pt
    }

    .c17 {
        font-weight: 400;
        font-size: 8pt
    }

    .c0 {
        font-size: 9pt;
        font-weight: 700
    }

    .c5 {
        color: inherit;
        text-decoration: inherit
    }

    .c3 {
        font-weight: 400;
        font-size: 9pt
    }

    .c20 {
        height: 12pt
    }

    .c16 {
        font-style: italic
    }

    .c12 {
        color: #000000
    }

    .c13 {
        color: #222222
    }

    .c4 {
        font-size: 9pt
    }

    .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Roboto";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1.0;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    li {
        color: #000000;
        font-size: 12pt;
        font-family: "Roboto"
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 12pt;
        font-family: "Roboto"
    }

    h1 {
        padding-top: 0pt;
        color: #000000;
        font-size: 12pt;
        padding-bottom: 0pt;
        font-family: "Roboto";
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    h2 {
        padding-top: 0pt;
        color: #000000;
        font-size: 12pt;
        padding-bottom: 0pt;
        font-family: "Roboto";
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    h3 {
        padding-top: 0pt;
        color: #000000;
        font-size: 12pt;
        padding-bottom: 0pt;
        font-family: "Roboto";
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    h4 {
        padding-top: 0pt;
        color: #000000;
        font-size: 12pt;
        padding-bottom: 0pt;
        font-family: "Roboto";
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    h5 {
        padding-top: 0pt;
        color: #000000;
        font-size: 12pt;
        padding-bottom: 0pt;
        font-family: "Roboto";
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    h6 {
        padding-top: 10pt;
        color: #000000;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: "Roboto";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: justify
    }
}