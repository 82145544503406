.transaction-table {

    table thead tr th {
        padding: 10px 24px;
        font-weight: 700;
        font-size: 14px;
        color: #141320;
        border-bottom: 0.5px solid #EFF7FF;
        border-top: 0.5px solid #EFF7FF;
        text-transform: capitalize;
        letter-spacing: normal;
    }

    table tbody tr td {
        padding: 30px 24px;
        font-size: 14px;
        border-bottom: 0.5px solid #EFF7FF;
        color: var(--chakra-colors-gray-main);

    }

    table tbody tr:last-of-type td {
        border-bottom: 0;

    }
    
}

@media (max-width: 768px) {
    .transaction-table table tbody tr td {
        width: max-content;
    }

}