$hover-cell-bg-color: #fff !important;
// $border-is-predicted-cell: 2px solid var(--chakra-colors-black-main);
$separator-cell_border: 2px solid var(--chakra-colors-twelve-green-200);
$separator-cell_width: 30px;


.tw-cohort {
    overflow: auto;
    max-height: 80vh;

    table {
        width: 100%;

        thead {
            >tr {
                >th {
                    padding: 5px;
                    text-align: center;
                    vertical-align: middle;
                    font-size: 10px;
                    color: var(--chakra-colors-secondaryGray-600);
                    text-transform: uppercase;

                    &.tw-cohort_cell__prefix_cell {
                        text-align: right;
                        padding-right: 20px;
                        box-shadow: none;
                    }
                }
            }
        }

        tr {

            >td {
                padding: 5px;
                text-align: center;
                vertical-align: middle;
                min-width: 60px;
                height: 30px;
                font-size: 14px;
                border-radius: 6px;
                border: 1px solid #fff;
                box-shadow: 14px 17px 40px 0px rgba(112, 144, 176, 0.08);

                &::before {
                    content: attr(data-text);
                    font-weight: 800;
                    height: 0;
                    display: block;
                    overflow: hidden;
                    visibility: hidden;
                }


                &.tw-cohort__cell--hovered {
                    background-color: $hover-cell-bg-color;
                    color: var(--chakra-colors-brand-700);
                    font-weight: 500;
                }

                &.tw-cohort_cell__prefix_cell {
                    text-align: right;
                    padding-right: 20px;
                    box-shadow: none;

                    &.tw-cohort__cell--hovered {

                        font-weight: 800;
                        color: var(--chakra-colors-secondaryGray-900)
                    }

                    &:first-of-type {
                        min-width: 135px;
                    }
                }

                &.tw-cohort_cell__separator {
                    border-left: $separator-cell_border;
                    box-shadow: none;
                    min-width: $separator-cell_width;
                }
            }

            //first column cells - left border
            >td:first-child {
                border-left: 'red';
                background-color: #fff;
            }


            &.tw-cohort__row-seperator {
                height: 30px;
            }

            &.tw-cohort__agg-row {
                >td {
                    box-shadow: none;
                    padding: 0;

                    >div {
                        padding: 5px;
                        background-color: #fff;
                        border: 1px solid var(--chakra-colors-secondaryGray-300);
                        border-radius: 8px;
                    }

                    &.tw-cohort_cell__separator {
                        border: 0;
                        border-left: $separator-cell_border;
                        box-shadow: none;
                        min-width: $separator-cell_width;
                    }

                    &.tw-cohort__cell--hovered {
                        background-color: $hover-cell-bg-color
                    }

                    &:first-child {
                        text-align: right;
                        padding-right: 20px;
                        border: none
                    }
                }
            }
        }
    }
}